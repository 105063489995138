import styled from "styled-components";
import { COLORS } from "../extras/constants";

const Container = styled.div`
  display: flex;
  margin-left: 5px;
  user-select: none;
  flex: 1;
`;
const Button = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${({ selected }) =>
    selected ? COLORS.activeEdge : COLORS.inactiveEdge};
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  margin: 0px 5px;
`;

const SelectableItems = ({
  items = [],
  selectedIndex = 0,
  onChange = (i) => {},
}) => {
  const handleClick = (index) => (evt) => {
    onChange(index);
  };
  return (
    <Container>
      {items.map((item, index) => (
        <Button
          key={`${item}_${index}`}
          selected={index === selectedIndex}
          onClick={handleClick(index)}
        >
          {item}
        </Button>
      ))}
    </Container>
  );
};

export default SelectableItems;
