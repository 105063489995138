export const types = {
  SET_ERROR: "SET_ERROR",
  HIDE_ERROR: "HIDE_ERROR",
  UPDATE_MODAL: "UPDATE_MODAL",
};

export const INITIAL_STATE = {
  error: null,
  isOpen: false,
  /* Para modales en general */
  modal: { show: false, text: null, finish: false },
};

export const actions = {
  setError: (error) => ({
    type: types.SET_ERROR,
    error: error,
  }),
  hideError: () => ({
    type: types.HIDE_ERROR,
  }),
  updateModal: (modal) => ({
    type: types.UPDATE_MODAL,
    modal,
  }),
};

export const selectors = {
  isOpen: ({ error }) => error.isOpen,
  getError: ({ error }) => error.error,
  getModal: ({ error }) => error.modal,
};

const errorReducer = (state = INITIAL_STATE, action) => {
  const { error } = action;
  if (error)
    return {
      ...state,
      error: error,
      isOpen: true,
    };
  if (action.type === types.HIDE_ERROR)
    return {
      ...state,
      error: null,
      isOpen: false,
    };
  if (action.type === types.UPDATE_MODAL)
    return { ...state, modal: { ...state.modal, ...action.modal } };
  return state;
};

export default errorReducer;
