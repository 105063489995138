import styled from "styled-components";
import Category from "../../components/Category";
import { PageContainer } from "../../components/StyledComponents";

const Container = styled(PageContainer)`
  padding: 0;
  overflow: hidden;
`;

const PageCategory = () => {
  return (
    <Container>
      <Category />
    </Container>
  );
};

export default PageCategory;
