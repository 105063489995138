import { actions } from "../reducers/attribute";
import { actions as errorActions } from "../reducers/error";
import * as attributes from "../api/attribute";

const attributeActions = {
  get: (identify) => async (dispatch) => {
    dispatch(actions.getRequest());
    try {
      const result = await attributes.getAttribute(identify);
      if (result.status === "ok")
        dispatch(actions.getSuccess(result.data ? result.data : null));
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  list:
    (props = {}) =>
    async (dispatch) => {
      dispatch(actions.listRequest());
      try {
        const result = await attributes.getAttributes(props);
        if (result.status === "ok") {
          await Promise.all(
            result.data.map(async (attr) => {
              attr.terms = (await attributes.getTerms(attr.id, {})).data;
            })
          );
          dispatch(
            actions.listSuccess(
              result.data ? result.data : [],
              result.pagination
            )
          );
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.listFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  create:
    ({ name, terms, ...rest }, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.createRequest());
      try {
        const result = await attributes.createAttribute({
          name,
          terms,
          ...rest,
        });
        if (result.status === "ok") {
          if (terms.length) {
            result.data.terms = (
              await attributes.batchTerms(result.data.id, { create: terms })
            ).data.create;
          } else {
            result.data.terms = [];
          }
          dispatch(actions.createSuccess(result.data ? result.data : []));
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.createFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  update:
    ({ identify, name, termsOperations = {} }, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.updateRequest());
      try {
        if (!identify) throw Error("Identificador de atributo inválido");
        const result = await attributes.updateAttribute({ identify, name });
        if (result.status === "ok") {
          if (termsOperations.create || termsOperations.delete) {
            await attributes.batchTerms(identify, termsOperations);
            result.data.terms = (await attributes.getTerms(identify, {})).data;
          }
          dispatch(
            actions.updateSuccess(
              result.data ? result.data : [],
              "Actualización exitosa"
            )
          );
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.updateFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  delete: (identify, handleSuccess) => async (dispatch) => {
    dispatch(actions.deleteRequest());
    try {
      const result = await attributes.deleteAttribute(identify);
      if (result.status === "ok") {
        dispatch(actions.deleteSuccess(identify));
        handleSuccess && handleSuccess(result.data);
      } else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.deleteFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  batch:
    (operations = {}, handleSuccess) =>
    async (dispatch) => {
      // dispatch(actions.batchRequest());
      // try {
      //   const result = await attributes.batchAttribute(operations);
      //   if (result.status === "ok") {
      //     dispatch(
      //       actions.batchSuccess(result.data ? result.data : "Correcto")
      //     );
      //     handleSuccess && handleSuccess(result.data);
      //   } else throw Error(result.data);
      // } catch (ex) {
      //   dispatch(actions.batchFailure(ex.message));
      //   dispatch(errorActions.setError(ex.message));
      // }
    },
};

export default attributeActions;
