import Gallery from "../../components/Gallery";

const Media = () => {
  return (
    <>
      <h1>Galería de imágenes</h1>
      <Gallery />
    </>
  );
};

export default Media;
