import React, { useState, Children } from "react";
import styled from "styled-components";
import { COLORS, ICONS } from "../../extras/constants";

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_sidenav_push

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px;
  height: 100%;
  width: ${({ open }) => (open ? "200px" : "60px")};
  z-index: 1;
  top: 0;
  left: 0;
  background-color: ${COLORS.backgroundMenu};
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
  color: ${COLORS.activeMenuText};
`;

const MenuButton = styled.a`
  cursor: pointer;
  margin-bottom: 20px;
  align-self: ${({ alignSelf }) => alignSelf};
`;

const Menu = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <SideBar open={open}>
      <MenuButton onClick={handleOpen} alignSelf={open ? "flex-end" : "center"}>
        <img src={open ? ICONS.arrow : ICONS.menu} width="29" height="29" />
      </MenuButton>
      {Children.map(children, (child) => {
        return React.cloneElement(child, { open: open });
      })}
    </SideBar>
  );
};

export default Menu;
