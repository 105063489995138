export const types = {
  PRODUCT_GET_REQUEST: "products/PRODUCT_GET_REQUEST",
  PRODUCT_GET_SUCCESS: "products/PRODUCT_GET_SUCCESS",
  PRODUCT_GET_FAILURE: "products/PRODUCT_GET_FAILURE",
  PRODUCT_LIST_REQUEST: "products/PRODUCT_LIST_REQUEST",
  PRODUCT_LIST_SUCCESS: "products/PRODUCT_LIST_SUCCESS",
  PRODUCT_LIST_FAILURE: "products/PRODUCT_LIST_FAILURE",
  PRODUCT_CREATE_REQUEST: "products/PRODUCT_CREATE_REQUEST",
  PRODUCT_CREATE_SUCCESS: "products/PRODUCT_CREATE_SUCCESS",
  PRODUCT_CREATE_FAILURE: "products/PRODUCT_CREATE_FAILURE",
  PRODUCT_DELETE_REQUEST: "products/PRODUCT_DELETE_REQUEST",
  PRODUCT_DELETE_SUCCESS: "products/PRODUCT_DELETE_SUCCESS",
  PRODUCT_DELETE_FAILURE: "products/PRODUCT_DELETE_FAILURE",
  PRODUCT_BATCH_REQUEST: "products/PRODUCT_BATCH_REQUEST",
  PRODUCT_BATCH_SUCCESS: "products/PRODUCT_BATCH_SUCCESS",
  PRODUCT_BATCH_FAILURE: "products/PRODUCT_BATCH_FAILURE",
  PRODUCT_UPDATE_REQUEST: "products/PRODUCT_UPDATE_REQUEST",
  PRODUCT_UPDATE_SUCCESS: "products/PRODUCT_UPDATE_SUCCESS",
  PRODUCT_UPDATE_FAILURE: "products/PRODUCT_UPDATE_FAILURE",
  PRODUCT_CLEAR: "products/PRODUCT_CLEAR",
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  product: null,
  products: [],
  error: null,
  pagination: null,
};

export const actions = {
  getRequest: () => ({
    type: types.PRODUCT_GET_REQUEST,
  }),
  getSuccess: (product) => ({
    type: types.PRODUCT_GET_SUCCESS,
    product,
  }),
  getFailure: (error) => ({
    type: types.PRODUCT_GET_FAILURE,
    error,
  }),
  listRequest: () => ({
    type: types.PRODUCT_LIST_REQUEST,
  }),
  listSuccess: (products, pagination) => ({
    type: types.PRODUCT_LIST_SUCCESS,
    products,
    pagination,
  }),
  listFailure: (error) => ({
    type: types.PRODUCT_LIST_FAILURE,
    error,
  }),
  createRequest: () => ({
    type: types.PRODUCT_CREATE_REQUEST,
  }),
  createSuccess: (message) => ({
    type: types.PRODUCT_CREATE_SUCCESS,
    message,
  }),
  createFailure: (error) => ({
    type: types.PRODUCT_CREATE_FAILURE,
    error,
  }),
  deleteRequest: () => ({
    type: types.PRODUCT_DELETE_REQUEST,
  }),
  deleteSuccess: (message) => ({
    type: types.PRODUCT_DELETE_SUCCESS,
    message,
  }),
  deleteFailure: (error) => ({
    type: types.PRODUCT_DELETE_FAILURE,
    error,
  }),
  batchRequest: () => ({
    type: types.PRODUCT_BATCH_REQUEST,
  }),
  batchSuccess: (message) => ({
    type: types.PRODUCT_BATCH_SUCCESS,
    message,
  }),
  batchFailure: (error) => ({
    type: types.PRODUCT_BATCH_FAILURE,
    error,
  }),
  updateRequest: () => ({
    type: types.PRODUCT_UPDATE_REQUEST,
  }),
  updateSuccess: (product, message) => ({
    type: types.PRODUCT_UPDATE_SUCCESS,
    message,
    product,
  }),
  updateFailure: (error) => ({
    type: types.PRODUCT_UPDATE_FAILURE,
    error,
  }),
  clearProduct: () => ({ type: types.PRODUCT_CLEAR }),
};

export const selectors = {
  isFetching: ({ product }) => product.isFetching,
  getMessage: ({ product }) => product.message,
  getError: ({ product }) => product.error,
  getProducts: ({ product }) => product.products,
  getProduct: ({ product }) => product.product,
  getPagination: ({ product }) => product.pagination,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PRODUCT_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        product: null,
      };
    case types.PRODUCT_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        product: action.product,
        pagination: action.pagination,
      };
    case types.PRODUCT_GET_FAILURE:
      return {
        ...state,
        product: null,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.PRODUCT_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        products: [],
      };
    case types.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        products: action.products,
        pagination: action.pagination,
      };
    case types.PRODUCT_LIST_FAILURE:
      return {
        ...state,
        products: [],
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.PRODUCT_CREATE_REQUEST:
      return { ...state, isFetching: true, message: null, error: null };
    case types.PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        error: null,
      };
    case types.PRODUCT_CREATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.PRODUCT_DELETE_REQUEST:
      return { ...state, isFetching: true, message: null, error: null };
    case types.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        error: null,
      };
    case types.PRODUCT_DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.PRODUCT_BATCH_REQUEST:
      return { ...state, isFetching: true, message: null, error: null };
    case types.PRODUCT_BATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        error: null,
      };
    case types.PRODUCT_BATCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.PRODUCT_UPDATE_REQUEST:
      return { ...state, isFetching: true, message: null, error: null };
    case types.PRODUCT_UPDATE_SUCCESS:
      let products = state.products.map((item) => {
        if (item.id === action.product.id) return action.product;
        return item;
      });
      return {
        ...state,
        products,
        product: action.product,
        isFetching: false,
        message: action.message,
        error: null,
      };
    case types.PRODUCT_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.PRODUCT_CLEAR:
      return {
        ...state,
        product: null,
      };
    default:
      return state;
  }
};

export default reducer;
