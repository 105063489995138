import * as API from "./index";

const CATEGORIES_ENDPOINT = "/wc/v3/products/categories";

export const getCategory = (identify) =>
  API.getJSONData(`${CATEGORIES_ENDPOINT}/${identify}`);

export const getCategories = ({ page = 1, per_page = 10, ...rest }) =>
  API.getJSONData(
    `${CATEGORIES_ENDPOINT}${API.getQueryFromObj({ page, per_page, ...rest })}`
  );

export const createCategory = async (category) =>
  API.getJSONData(CATEGORIES_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(category),
  });

export const updateCategory = ({ identify, ...params }) =>
  API.getJSONData(`${CATEGORIES_ENDPOINT}/${identify}`, {
    method: "PUT",
    body: JSON.stringify(params),
  });

export const deleteCategory = (identify, force = false) =>
  API.getJSONData(`${CATEGORIES_ENDPOINT}/${identify}?force=${force}`, {
    method: "DELETE",
  });

export const batchCategory = (operations) =>
  API.getJSONData(`${CATEGORIES_ENDPOINT}/batch`, {
    method: "POST",
    body: JSON.stringify(operations),
  });
