import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import AsyncStorage from "redux-persist/lib/storage"; // defaults to localStorage for web

import product from "./product";
import category from "./category";
import tag from "./tag";
import media from "./media";
import error from "./error";
import page from "./page";
import user from "./user";
import attribute from "./attribute";
import report from "./report";

const rootPersistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: [
    "product",
    "error",
    "page",
    "media",
    "category",
    "user",
    "attribute",
    "tag",
    "report",
  ],
};

const userPersistConfig = {
  key: "user",
  storage: AsyncStorage,
  whitelist: ["logged", "user"],
};

const reducers = combineReducers({
  user: persistReducer(userPersistConfig, user),
  product,
  error,
  page,
  media,
  category,
  attribute,
  tag,
  report,
});

export default persistReducer(rootPersistConfig, reducers);
