import styled from "styled-components";
import { COLORS } from "../../extras/constants";
import TableRow from "./TableRow";

const TableRowHead = styled(TableRow)`
  color: ${COLORS.tableHeaderColor};
  background-color: ${COLORS.tableHeaderBackground};
`;

export default TableRowHead;
