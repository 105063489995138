import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors } from "../reducers/user";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLogged = useSelector(selectors.isLogged);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
