import { useEffect } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { MdWarning } from "react-icons/md";
import { selectors as userSelectors } from "../../reducers/user";
import { selectors as reportSelectors } from "../../reducers/report";
import { report as reportActions } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { COLORS } from "../../extras/constants";
import {
  CardContainer,
  Row as StyledRow,
} from "../../components/StyledComponents";

const Card = styled(CardContainer)`
  min-width: 0;
  margin: 10px;
`;

const Row = styled(StyledRow)`
  justify-content: center;
`;

const Home = () => {
  const dispatch = useDispatch();
  const { name, lastname, email } = useSelector(userSelectors.getUser);
  const { total_sales, average_sales, total_orders } = useSelector(
    reportSelectors.getSales
  );
  const total_products = useSelector(reportSelectors.getProductTotal);
  const top_sellers = useSelector(reportSelectors.getTopSellers);
  const customer_total = useSelector(reportSelectors.getCustomerTotal);

  useEffect(() => {
    dispatch(reportActions.getSales());
    dispatch(reportActions.getProductTotal());
    dispatch(reportActions.getTopSellers());
    dispatch(reportActions.getCustomerTotal());
  }, []);

  return (
    <>
      <h1>Bienvenido a Woome</h1>
      <h4>{`${name} ${lastname}`}</h4>
      <h4>{`${email}`}</h4>
      <Row>
        <Card>
          <h4>Total vendido</h4>
          <h2>${total_sales}</h2>
        </Card>
        <Card>
          <h4>Promedio ventas</h4>
          <h2>${average_sales}</h2>
        </Card>
        <Card>
          <h4>Total de órdenes</h4>
          <h2>{total_orders}</h2>
        </Card>
        <Card>
          <h4>Cantidad de productos</h4>
          <h2>{total_products}</h2>
        </Card>
        <Card>
          <h4>Producto más vendido</h4>
          <h2>{top_sellers.length ? top_sellers[0].title : ""}</h2>
        </Card>
        <Card>
          <h4>Cantidad de clientes</h4>
          <h2>{customer_total}</h2>
        </Card>
      </Row>
      {/* <Card>
        <h2>Sitio en Construcción</h2>
        <IconContext.Provider
          value={{
            size: "80px",
            color: COLORS.activeMenuText,
          }}
        >
          <MdWarning />
        </IconContext.Provider>
        <p>Algunas secciones pueden estar sin terminar</p>
      </Card> */}
    </>
  );
};

export default Home;
