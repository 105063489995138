import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams, useLocation, Redirect } from "react-router-dom";
/* Constantes */
import { product as productActions } from "../../actions";
import { actions as errorActions } from "../../reducers/error";
import { selectors as productSelectors } from "../../reducers/product";
import { COLORS } from "../../extras/constants";
import { getDifference } from "../../extras/objects";
/* General componentes */
import ImportantText from "../../components/ImportantText";
import SelectableItems from "../../components/SelectableItems";
import Checkbox from "../../components/Checkbox";
import ImagePicker from "../../components/ImagePicker";
import ImagePickerList from "../../components/ImagePickerList";
import Popup from "../../components/Popup";
import Gallery from "../../components/Gallery";
import Category from "../../components/Category";
import {
  Divider,
  PageContainer,
  Button as StyledButton,
  FieldHeader,
  Text,
  Row,
  Column,
  Combobox,
} from "../../components/StyledComponents";
import Tag from "../../components/Tag";
/* Internal components */
import ShowAttributes from "./components/ShowAttributes";
import EditAttributes from "./components/EditAttributes";
import JoditEditor from "jodit-react";

const CURRENCIES = ["$"];

const STOCK_STATUS = [
  { label: "En stock", value: "instock" },
  { label: "Agotado", value: "outofstock" },
  { label: "En reserva", value: "onbackorder" },
];

const JSON_DEFAULT = {
  name: "",
  type: "simple",
  description: "",
  sku: "",
  regular_price: 0,
  images: [],
  sale_price: 0,
  categories: [],
  manage_stock: false,
  stock_quantity: 0,
  stock_status: "instock" /* instock, outofstock, onbackorder */,
  short_description: "",
  attributes: [],
  status: "draft" /* draft, publish, pending, private */,
  tags: [],
};

const ButtonsRight = styled(Row)`
  flex-direction: row-reverse;
  margin-top: 20px;
`;

const Button = styled(StyledButton)`
  padding: 12px 20px;
  border-radius: 10px;
  margin: 5px;
`;

const ButtonNext = styled.button`
  height: 60px;
  width: 200px;
  border-radius: 30px;
  margin: 0 10px 0 10px;
  padding: 10px;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  align-self: flex-end;
  background-color: ${({ disabled }) =>
    disabled ? COLORS.inactiveButton : COLORS.activeButton};
  color: white;
  cursor: pointer;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateProduct = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { identify } = useParams();
  const duplicate = useQuery().get("duplicate");
  const modifyProduct = useSelector(productSelectors.getProduct);
  /* Internal Status */
  const [difference, setDifference] = useState({});
  const [product, setProduct] = useState(JSON_DEFAULT);
  const [currency, setCurrency] = useState(0);
  const [reducedPrice, setReducedPrice] = useState(false);
  const [popcategory, setPopcategory] = useState(false);
  const [poptags, setPoptags] = useState(false);
  const [popattribute, setPopattribute] = useState(false);
  const [popimage, setPopimage] = useState(false);
  const [redirect, setRedirect] = useState(false);
  /* JODIT EDITOR */
  const editorDescription = useRef(null);
  const editorShortDescription = useRef(null);
  const configDescription = {
    readonly: false,
    placeholder: "Describa su producto aquí",
  };
  const configShortDescription = {
    readonly: false,
    placeholder: "Descripción corta",
  };
  /* END JODIT EDITOR */
  const showModal = (message, seconds) => {
    dispatch(
      errorActions.updateModal({
        show: true,
        text: message,
        finish: true,
      })
    );
    setTimeout(() => {
      dispatch(
        errorActions.updateModal({
          finish: false,
          show: false,
          text: null,
          successText: message,
        })
      );
    }, 1000 * seconds);
  };
  const handleChanges =
    (name) =>
    ({ target: { value } }) => {
      const changedProduct = { ...product, [name]: value };
      if (identify) setDifference(getDifference(modifyProduct, changedProduct));
      setProduct(changedProduct);
    };

  const handleDescription = (description) => {
    const changedProduct = { ...product, description };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
  };

  const handleShortDescription = (short_description) => {
    const changedProduct = { ...product, short_description };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
  };

  const handleSuccess = () => {
    if (identify) {
      setDifference({});
      showModal("¡PRODUCTO MODIFICADO!", 4);
    } else {
      setProduct(JSON_DEFAULT);
      showModal("¡PRODUCTO AGREGADO!", 4);
    }
    setRedirect(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (identify) {
      dispatch(
        productActions.update({ identify, ...difference }, handleSuccess)
      );
    } else {
      if (!reducedPrice) product.sale_price = undefined;
      dispatch(productActions.create(product, handleSuccess));
    }
  };

  const handlePublish = (e) => {
    const changedProduct = { ...product, status: "publish" };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
    if (identify) {
      dispatch(
        productActions.update(
          { identify, ...getDifference(modifyProduct, changedProduct) },
          handleSuccess
        )
      );
    } else {
      if (!reducedPrice) changedProduct.sale_price = undefined;
      dispatch(productActions.create(changedProduct, handleSuccess));
    }
  };

  const openPopimages = () => {
    setPopimage(true);
  };

  const handleImages = (images) => {
    const changedProduct = { ...product, images };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
    setPopimage(false);
  };

  const handleCategories = (categories) => {
    const changedProduct = { ...product, categories };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
  };

  const handleTags = (tags) => {
    const changedProduct = { ...product, tags };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
  };

  const handleDeleteAttribute = (id) => {
    const changedProduct = {
      ...product,
      attributes: product.attributes.filter((attr) => attr.id !== id),
    };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
  };

  const handleAttributes = (attributes) => {
    const changedProduct = { ...product, attributes };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
    setPopattribute(false);
  };

  const handleManageStock = (checked) => {
    const changedProduct = { ...product, manage_stock: checked };
    if (identify) setDifference(getDifference(modifyProduct, changedProduct));
    setProduct(changedProduct);
  };

  useEffect(() => {
    if (identify || duplicate)
      dispatch(productActions.get(identify | duplicate));
    else setProduct(JSON_DEFAULT);
  }, [identify]);

  useEffect(() => {
    if (modifyProduct) {
      const prod = { ...modifyProduct };
      if (!identify && duplicate) {
        delete prod.id;
        prod.status = "draft";
      }
      setProduct(prod);
    }
    return () => {
      if (modifyProduct) {
        dispatch(productActions.clearProduct());
      }
    };
  }, [modifyProduct]);

  return (
    <PageContainer>
      {redirect ? <Redirect to="/product/list" /> : null}
      {identify ? <h1>ID Producto: {identify}</h1> : null}
      <Popup onClose={() => setPopimage(false)} visible={popimage}>
        <Gallery onSubmit={handleImages} defaultselection={product.images} />
      </Popup>
      <Popup onClose={() => setPopcategory(false)} visible={popcategory}>
        <Category
          onChange={handleCategories}
          categoriesSelected={product.categories}
          onSubmit={() => setPopcategory(false)}
        />
      </Popup>
      <Popup onClose={() => setPopattribute(false)} visible={popattribute}>
        <EditAttributes
          onChange={handleAttributes}
          attributes={product.attributes}
        />
      </Popup>
      <Popup onClose={() => setPoptags(false)} visible={poptags}>
        <Tag
          onChange={handleTags}
          onSubmit={() => setPoptags(false)}
          tagsSelected={product.tags}
        />
      </Popup>
      <Row>
        <Column flex={1}>
          <Row>
            <ImagePicker
              image={product.images ? product.images[0] : undefined}
              onAdd={openPopimages}
            />
            <ImagePickerList
              images={product.images.slice(1)}
              onAdd={openPopimages}
            />
          </Row>
          <Row>
            <ImportantText
              title="SKU"
              placeholder="00000"
              value={product.sku}
              onChange={handleChanges("sku")}
            />
          </Row>
        </Column>
        <Column flex={2}>
          <Row>
            <Text
              placeholder="Título aquí"
              value={product.name}
              onChange={handleChanges("name")}
            />
          </Row>
          <Row>
            <Text
              type="number"
              min="0"
              placeholder="$0,00"
              flex={0.3}
              value={product.regular_price}
              onChange={handleChanges("regular_price")}
            />
            <SelectableItems
              items={CURRENCIES}
              selectedIndex={currency}
              onChange={setCurrency}
            />
          </Row>
          <Row>
            <Checkbox
              label="Activar precio rebajado"
              checked={reducedPrice}
              onChange={(e) => setReducedPrice(e.target.checked)}
            />
            {reducedPrice && (
              <>
                <Text
                  type="number"
                  min="0"
                  placeholder="$0,00"
                  flex={0.4}
                  value={product.sale_price}
                  onChange={handleChanges("sale_price")}
                />
                <SelectableItems
                  items={CURRENCIES}
                  selectedIndex={currency}
                  onChange={setCurrency}
                />
              </>
            )}
          </Row>
          <Row>
            <Checkbox
              label="Activar Stock"
              checked={product.manage_stock}
              onChange={(e) => handleManageStock(e.target.checked)}
            />
            {product.manage_stock ? (
              <Text
                type="number"
                min="0"
                placeholder="0"
                flex={0.4}
                value={product.stock_quantity}
                onChange={handleChanges("stock_quantity")}
              />
            ) : (
              <>
                <span>Estado del inventario</span>
                <Combobox
                  value={product.stock_status}
                  onChange={handleChanges("stock_status")}
                >
                  {STOCK_STATUS.map(({ label, value }) => (
                    <option value={value} key={`${label}_${value}`}>
                      {label}
                    </option>
                  ))}
                </Combobox>
              </>
            )}
          </Row>
          {product.attributes.map(({ name, options, id }) => (
            <ShowAttributes
              name={name}
              options={options}
              key={`${name}`}
              id={id}
              onDelete={handleDeleteAttribute}
            />
          ))}
        </Column>
      </Row>
      <Column>
        <FieldHeader>Descripción Corta</FieldHeader>
        <JoditEditor
          ref={editorShortDescription}
          value={product.short_description}
          config={configShortDescription}
          onBlur={handleShortDescription}
          // onChange={(newContent) => {}}
          // tabIndex={1}
        />
        <FieldHeader>Descripción Larga</FieldHeader>
        <JoditEditor
          ref={editorDescription}
          value={product.description}
          config={configDescription}
          onBlur={handleDescription}
          // onChange={(newContent) => {}}
          // tabIndex={1}
        />
      </Column>
      <Row>
        {product.attributes && product.attributes.length ? (
          <Button
            onClick={() => setPopattribute(true)}
            backgroundcolor={COLORS.activeSecundaryButton}
          >
            Modificar atributos
          </Button>
        ) : (
          <Button
            onClick={() => setPopattribute(true)}
            backgroundcolor={COLORS.inactiveButton}
          >
            Agregar atributos +
          </Button>
        )}

        {product.categories && product.categories.length ? (
          <Button
            onClick={() => setPopcategory(true)}
            backgroundcolor={COLORS.activeSecundaryButton}
          >
            Modificar categorías
          </Button>
        ) : (
          <Button
            onClick={() => setPopcategory(true)}
            backgroundcolor={COLORS.inactiveButton}
          >
            Agregar categorías +
          </Button>
        )}
        {product.tags && product.tags.length ? (
          <Button
            onClick={() => setPoptags(true)}
            backgroundcolor={COLORS.activeSecundaryButton}
          >
            Modificar tags
          </Button>
        ) : (
          <Button
            onClick={() => setPoptags(true)}
            backgroundcolor={COLORS.inactiveButton}
          >
            Agregar tags +
          </Button>
        )}
      </Row>
      <Divider />
      <ButtonsRight>
        {product.status !== "publish" ? (
          <ButtonNext
            onClick={handlePublish}
            disabled={
              !(
                product.name &&
                product.sku &&
                product.description &&
                product.regular_price
              )
            }
          >
            Publicar
          </ButtonNext>
        ) : null}
        {identify ? (
          <ButtonNext
            onClick={handleSubmit}
            disabled={!Object.keys(difference).length}
          >
            Guardar
          </ButtonNext>
        ) : (
          <ButtonNext
            onClick={handleSubmit}
            disabled={
              !(
                product.name &&
                product.sku &&
                product.short_description &&
                product.regular_price
              )
            }
          >
            Borrador
          </ButtonNext>
        )}
      </ButtonsRight>
    </PageContainer>
  );
};

export default CreateProduct;
