import styled from "styled-components";
import { COLORS } from "../../extras/constants";

const TableRow = styled.tr`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${({ flexValue = 1 }) => flexValue};
  align-self: stretch;
  background-color: white;
  margin-bottom: 10px;
  min-height: 70px;
  max-height: 70px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export default TableRow;
