import styled from "styled-components";
import { COLORS } from "../extras/constants";
import { IconContext } from "react-icons/lib";
import { MdStar } from "react-icons/md";

const Container = styled.label`
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1em;
  color: ${({ checked, disabledColor }) =>
    checked ? COLORS.checked : disabledColor};
  font-weight: bold;
`;

const Input = styled.input`
  display: none;
`;

const StarCheckbox = ({
  value,
  checked,
  onChange,
  name,
  id,
  disabled,
  label,
  disabledColor = COLORS.inactiveEdge,
}) => {
  const handleCheck = (e) => {
    if (typeof onChange === "function") onChange(e);
  };

  return (
    <Container
      htmlFor={id}
      disabled={disabled}
      checked={checked}
      disabledColor={disabledColor}
    >
      {label}
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={handleCheck}
      />
      <IconContext.Provider
        value={{
          size: "30px",
          color: checked ? COLORS.checkedStar : COLORS.uncheckedStar,
        }}
      >
        <MdStar />
      </IconContext.Provider>
    </Container>
  );
};

export default StarCheckbox;
