import styled from "styled-components";
import { COLORS } from "../../extras/constants";
import { Button as GeneralButton } from "../StyledComponents";

const Button = styled(GeneralButton)`
  position: relative;
  background-color: ${({ selected }) =>
    selected ? COLORS.activeBubble : COLORS.inactiveBubble};
  margin: 5px 10px 0 0;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  background-color: black;
  color: white;
  font-size: 10px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;

  position: absolute;
  top: -7px;
  right: -7px;
`;

const Bubble = ({
  value = {},
  selected = false,
  onSelect,
  onDelete,
  labelField = "name",
}) => {
  const handleSelect = (evt) => {
    if (typeof onSelect === "function") onSelect(value);
  };
  const handleDelete = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (typeof onDelete === "function") onDelete(value);
  };
  return (
    <Button selected={selected} onClick={handleSelect}>
      {typeof value === "string"
        ? value
        : typeof value === "object"
        ? value[labelField]
        : toString(value)}
      {onDelete && <CloseButton onClick={handleDelete}>X</CloseButton>}
    </Button>
  );
};

export default Bubble;
