import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../extras/constants";

const HEADER_HEIGHT = "25px";
const GENERAL_HEIGHT = "200px";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: 1px solid ${({ color }) => color};
  border-radius: 5px;
  height: ${({ height }) => height};
  overflow-y: hidden;
  transition: all 0.5s;
  align-self: stretch;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
  align-self: stretch;
  align-items: stretch;
  overflow-y: scroll;
`;

const Header = styled.a`
  justify-content: space-between;
  display: flex;
  background-color: ${({ color }) => color};
  align-self: stretch;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: 20px;
  font-weight: bold;
  color: ${({ titleColor }) => titleColor};
  cursor: pointer;
  min-height: ${HEADER_HEIGHT};
  max-height: ${HEADER_HEIGHT};
  user-select: none;
`;

const CollapsibleContainer = ({
  height = GENERAL_HEIGHT,
  title = "",
  color = COLORS.borderShowAttribute,
  titleColor = "white",
  children,
  HeaderButton,
}) => {
  const [containerHeight, setContainerHeight] = useState(HEADER_HEIGHT);
  const [collapsed, setCollapsed] = useState(true);
  const handleCollapse = () => {
    if (collapsed) {
      setCollapsed(false);
      setContainerHeight(height);
    } else {
      setCollapsed(true);
      setContainerHeight(HEADER_HEIGHT);
    }
  };
  return (
    <Container color={color} height={containerHeight}>
      <Header color={color} titleColor={titleColor} onClick={handleCollapse}>
        {title}
        {HeaderButton ? <HeaderButton /> : null}
      </Header>
      <Box>{children}</Box>
    </Container>
  );
};

export default CollapsibleContainer;
