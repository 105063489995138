import * as API from "./index";

const USERS_ENDPOINT = "/users";

export const login = ({ email, password }) =>
  API.getJSONWoome(`${USERS_ENDPOINT}/login`, {
    method: "POST",
    body: JSON.stringify({ email, password }),
  });
//   new Promise((resolve, reject) => {
//     setTimeout(() => {
//       if (
//         (username === "nicolas" || username === "angimenez1996@gmail.com") &&
//         password === "nicolas123"
//       ) {
//         resolve({
//           data: {
//             username: "nicolas",
//             email: "angimenez1996@gmail.com",
//             name: "Nicolas",
//             lastname: "Giménez",
//             country: "Uruguay",
//             city: "Montevideo",
//             address: "Aparicio Saravia 1234",
//             business_name: "Nicolás SA",
//             api_url: "https://woome.nicolasgimenez.com/wordpress",
//             api_user: "ck_5374580112df7ea90058211e08fb966beea64e5e",
//             api_pass: "cs_fb94047b150b96fc90d2e497f99652f2e82f746b",
//           },
//           status: "ok",
//         });
//       } else {
//         resolve({
//           status: "error",
//           data: "Usuario y/o contraseña incorrectos",
//         });
//       }
//     }, 1000);
//   });

export const register = ({ email, password, ...rest }) =>
  API.getJSONWoome(`${USERS_ENDPOINT}/register`, {
    method: "POST",
    body: JSON.stringify({ email, password, ...rest }),
  });
// API.getJSONData(`${USERS_ENDPOINT}/login`, {
//   method: "POST",
//   body: JSON.stringify(user),
// });
