import styled from "styled-components";

const TableCellHead = styled.th`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: ${({ flexValue = 1 }) => flexValue};
  align-self: stretch;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export default TableCellHead;
