import styled from "styled-components";

const TableCell = styled.td`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex: ${({ flexValue = 1 }) => flexValue};
  padding: 0 0;
`;

export default TableCell;
