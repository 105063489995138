import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch /*
  useLocation,
  Redirect,*/,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
// Pages
import Login from "../pages/Login";
import Attribute from "../pages/Attribute";
import Home from "../pages/Home";
import Media from "../pages/Media";
import CreateProduct from "../pages/Product/Create";
import ListProduct from "../pages/Product/List";
import Category from "../pages/Category";
import Register from "../pages/Register";
import Tag from "../pages/Tag";
// Popups
import Fetching from "../components/Fetching";
import ErrorNotification from "../components/ErrorNotification";
// Components
import Menu from "../components/Menu";
import MenuItem from "../components/Menu/MenuItem";
import PrivateRoute from "./PrivateRoute";
// Reducers
import { selectors } from "../reducers/user";
// Actions
import userActions from "../actions/user";
import {
  MdCardTravel,
  MdDashboard,
  MdVisibility,
  MdTurnedIn,
  MdImage,
  MdList,
} from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";
import { FaTags } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { COLORS } from "../extras/constants";
import ModalFetching from "../components/ModalFetching";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
`;

const Ok = styled.button`
  font-size: 0.8em;
  color: white;
  font-weight: 600;
  display: flex;
  background: ${COLORS.inactiveMenuText};
  margin: 20px 20px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  margin-top: 150px;
  cursor: pointer;
`;

const Navigations = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(selectors.isLogged);
  const reducers = useSelector((state) => state);
  const [fetch, setFetch] = useState(false);
  useEffect(() => {
    let isFetching = false;
    Object.keys(reducers).forEach((item) => {
      if (reducers[item].isFetching && reducers[item].isFetching === true) {
        isFetching = true;
      }
    });
    setFetch(isFetching);
  }, [reducers]);

  const handleLogout = () => {
    dispatch(userActions.logout());
  };

  return (
    <Router>
      <ModalFetching />
      <Fetching show={fetch} />
      <ErrorNotification />
      {isLogged && (
        <Menu>
          <MenuItem url="/" Icon={() => <MdDashboard />}>
            Dashboard
          </MenuItem>
          <MenuItem url="/product/create" Icon={() => <MdCardTravel />}>
            Producto
          </MenuItem>
          <MenuItem url="/product/list" Icon={() => <MdList />}>
            Listados
          </MenuItem>
          <MenuItem url="/product/attribute" Icon={() => <MdVisibility />}>
            Atributos
          </MenuItem>
          <MenuItem url="/product/category" Icon={() => <MdTurnedIn />}>
            Categorías
          </MenuItem>
          <MenuItem url="/product/tag" Icon={() => <FaTags />}>
            Tags
          </MenuItem>
          <MenuItem url="/media" Icon={() => <MdImage />}>
            Galería
          </MenuItem>
          {/* <MenuItem url="/login">Login</MenuItem>
          <MenuItem url="/register">Registro</MenuItem> */}
          <Ok onClick={handleLogout} title="Cerrar sesión">
            <IconContext.Provider
              value={{
                size: "35px",
              }}
            >
              <div>
                <IoMdLogOut />
              </div>
            </IconContext.Provider>
          </Ok>
        </Menu>
      )}
      <Container>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/product/attribute" component={Attribute} />
          <PrivateRoute exact path="/product/category" component={Category} />
          <PrivateRoute exact path="/product/tag" component={Tag} />
          <PrivateRoute
            exact
            path="/product/create"
            component={CreateProduct}
          />
          <PrivateRoute exact path="/product/list" component={ListProduct} />
          <PrivateRoute
            exact
            path="/product/:identify"
            component={CreateProduct}
          />
          <PrivateRoute exact path="/media" component={Media} />
          <PrivateRoute path="*" component={() => <h1>404 Not found</h1>} />
        </Switch>
      </Container>
    </Router>
  );
};

export default Navigations;
