import * as API from "./index";

const TAGS_ENDPOINT = "/wc/v3/products/tags";

export const getTag = (identify) =>
  API.getJSONData(`${TAGS_ENDPOINT}/${identify}`);

export const getTags = ({ page = 1, per_page = 10, ...rest }) =>
  API.getJSONData(
    `${TAGS_ENDPOINT}${API.getQueryFromObj({ page, per_page, ...rest })}`
  );

export const createTag = async (tag) =>
  API.getJSONData(TAGS_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(tag),
  });

export const updateTag = ({ identify, ...params }) =>
  API.getJSONData(`${TAGS_ENDPOINT}/${identify}`, {
    method: "PUT",
    body: JSON.stringify(params),
  });

export const deleteTag = (identify, force = false) =>
  API.getJSONData(`${TAGS_ENDPOINT}/${identify}?force=${force}`, {
    method: "DELETE",
  });

export const batchTag = (operations) =>
  API.getJSONData(`${TAGS_ENDPOINT}/batch`, {
    method: "POST",
    body: JSON.stringify(operations),
  });
