import styled from "styled-components";
import { Column } from "../../components/StyledComponents";

const Container = styled(Column)`
  background: linear-gradient(
    135deg,
    #0088ff,
    #0a82f6,
    #117bee,
    #1675e5,
    #196edd,
    #1b68d4,
    #1c62cc
  );
  align-items: center;
  justify-content: flex-start;
`;

const Hi = styled.h1`
  margin-top: 40vh;
  font-size: 3em;
  color: white;
  font-weight: bolder;
`;

const HiSub = styled.p`
  font-size: 1.2em;
  color: white;
  font-weight: medium;
  text-align: center;
  width: 60%;
`;

const Welcome = ({
  title = "Bienvenido!",
  description = "Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem",
}) => {
  return (
    <Container>
      <Hi>{title}</Hi>
      <HiSub>{description}</HiSub>
      <div>
        <a>FB</a> <a>IG</a>
      </div>
    </Container>
  );
};

export default Welcome;
