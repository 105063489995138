import React from "react";
import styled, { keyframes } from "styled-components";
import {
  CardContainer,
  DarkBackground,
  PopupContainer,
} from "./StyledComponents";

const SpinContainer = styled(PopupContainer)`
  z-index: 102;
`;

const SpinBackground = styled(DarkBackground)`
  z-index: 101;
`;

const Container = styled(CardContainer)`
  background-color: black;
  opacity: 0.5;
`;

const Fetching = ({ show }) =>
  show ? (
    <>
      <SpinBackground />
      <SpinContainer>
        <Container>
          <Spin />
        </Container>
      </SpinContainer>
    </>
  ) : null;

const spin_safari = keyframes`
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spin = styled.div`
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: ${spin_safari} 0.8s linear infinite;
  animation: ${spin} 0.8s linear infinite;
`;

export default Fetching;
