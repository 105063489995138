import styled from "styled-components";
import { COLORS } from "../../extras/constants";

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${({ selected }) =>
    selected ? COLORS.activeSelectableItem : COLORS.inactiveSelectableItem};
  border-radius: 5px;
  color: white;
  margin: 5px 5px;
  font-weight: bold;
  user-select: none;
`;
const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  background-color: ${COLORS.deleteButton};
  color: white;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`;

const ColumnItem = ({
  value = {},
  selected = false,
  onSelect,
  onDelete,
  labelField = "name",
}) => {
  const handleSelect = (evt) => {
    if (typeof onSelect === "function") onSelect(value);
  };
  const handleDelete = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (typeof onDelete === "function") onDelete(value);
  };
  return (
    <Row>
      <Button selected={selected} onClick={handleSelect}>
        {typeof value === "string"
          ? value
          : typeof value === "object"
          ? value[labelField]
          : toString(value)}
      </Button>
      <CloseButton onClick={handleDelete}>X</CloseButton>
    </Row>
  );
};

export default ColumnItem;
