export const types = {
  ATTRIBUTE_GET_REQUEST: "attributes/ATTRIBUTE_GET_REQUEST",
  ATTRIBUTE_GET_SUCCESS: "attributes/ATTRIBUTE_GET_SUCCESS",
  ATTRIBUTE_GET_FAILURE: "attributes/ATTRIBUTE_GET_FAILURE",
  ATTRIBUTE_LIST_REQUEST: "attributes/ATTRIBUTE_LIST_REQUEST",
  ATTRIBUTE_LIST_SUCCESS: "attributes/ATTRIBUTE_LIST_SUCCESS",
  ATTRIBUTE_LIST_FAILURE: "attributes/ATTRIBUTE_LIST_FAILURE",
  ATTRIBUTE_CREATE_REQUEST: "attributes/ATTRIBUTE_CREATE_REQUEST",
  ATTRIBUTE_CREATE_SUCCESS: "attributes/ATTRIBUTE_CREATE_SUCCESS",
  ATTRIBUTE_CREATE_FAILURE: "attributes/ATTRIBUTE_CREATE_FAILURE",
  ATTRIBUTE_UPDATE_REQUEST: "attributes/ATTRIBUTE_UPDATE_REQUEST",
  ATTRIBUTE_UPDATE_SUCCESS: "attributes/ATTRIBUTE_UPDATE_SUCCESS",
  ATTRIBUTE_UPDATE_FAILURE: "attributes/ATTRIBUTE_UPDATE_FAILURE",
  ATTRIBUTE_DELETE_REQUEST: "attributes/ATTRIBUTE_DELETE_REQUEST",
  ATTRIBUTE_DELETE_SUCCESS: "attributes/ATTRIBUTE_DELETE_SUCCESS",
  ATTRIBUTE_DELETE_FAILURE: "attributes/ATTRIBUTE_DELETE_FAILURE",
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  error: null,
  pagination: null,
  attribute: null,
  attributes: [],
};

export const actions = {
  getRequest: () => ({
    type: types.ATTRIBUTE_GET_REQUEST,
  }),
  getSuccess: (attribute) => ({
    type: types.ATTRIBUTE_GET_SUCCESS,
    attribute,
  }),
  getFailure: (error) => ({
    type: types.ATTRIBUTE_GET_FAILURE,
    error,
  }),
  listRequest: () => ({
    type: types.ATTRIBUTE_LIST_REQUEST,
  }),
  listSuccess: (attributes) => ({
    type: types.ATTRIBUTE_LIST_SUCCESS,
    attributes,
  }),
  listFailure: (error) => ({
    type: types.ATTRIBUTE_LIST_FAILURE,
    error,
  }),
  createRequest: () => ({
    type: types.ATTRIBUTE_CREATE_REQUEST,
  }),
  createSuccess: (attribute) => ({
    type: types.ATTRIBUTE_CREATE_SUCCESS,
    attribute,
  }),
  createFailure: (error) => ({
    type: types.ATTRIBUTE_CREATE_FAILURE,
    error,
  }),
  updateRequest: () => ({
    type: types.ATTRIBUTE_UPDATE_REQUEST,
  }),
  updateSuccess: (attribute) => ({
    type: types.ATTRIBUTE_UPDATE_SUCCESS,
    attribute,
  }),
  updateFailure: (error) => ({
    type: types.ATTRIBUTE_UPDATE_FAILURE,
    error,
  }),
  deleteRequest: () => ({
    type: types.ATTRIBUTE_DELETE_REQUEST,
  }),
  deleteSuccess: (identify) => ({
    type: types.ATTRIBUTE_DELETE_SUCCESS,
    identify,
  }),
  deleteFailure: (error) => ({
    type: types.ATTRIBUTE_DELETE_FAILURE,
    error,
  }),
};

export const selectors = {
  isFetching: ({ attribute }) => attribute.isFetching,
  getMessage: ({ attribute }) => attribute.message,
  getError: ({ attribute }) => attribute.error,
  getAttributes: ({ attribute }) => attribute.attributes,
  getAttribute: ({ attribute }) => attribute.attribute,
  getPagination: ({ attribute }) => attribute.pagination,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ATTRIBUTE_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        attribute: null,
      };
    case types.ATTRIBUTE_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        attribute: action.attribute,
        pagination: action.pagination,
      };
    case types.ATTRIBUTE_GET_FAILURE:
      return {
        ...state,
        attribute: null,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.ATTRIBUTE_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        attributes: [],
      };
    case types.ATTRIBUTE_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        attributes: action.attributes,
        pagination: action.pagination,
      };
    case types.ATTRIBUTE_LIST_FAILURE:
      return {
        ...state,
        attributes: [],
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.ATTRIBUTE_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
      };
    case types.ATTRIBUTE_CREATE_SUCCESS:
      const attributes = [...state.attributes];
      attributes.push(action.attribute);
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        attributes,
      };
    case types.ATTRIBUTE_CREATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.ATTRIBUTE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
      };
    case types.ATTRIBUTE_UPDATE_SUCCESS:
      const updatedAttributes = state.attributes.map((attr) => {
        if (attr.id === action.attribute.id) return action.attribute;
        return attr;
      });
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        attributes: updatedAttributes,
      };
    case types.ATTRIBUTE_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.ATTRIBUTE_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
      };
    case types.ATTRIBUTE_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        attributes: state.attributes.filter(
          (attr) => attr.id !== action.identify
        ),
      };
    case types.ATTRIBUTE_DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
