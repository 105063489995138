import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { category as categoryActions } from "../../actions";
import { selectors as categorySelectors } from "../../reducers/category";
import RecursiveList, { recursiveOptionsMap } from "../RecursiveList";
import {
  Button,
  Column,
  Row,
  BubbleContainer,
  Text,
  Combobox
} from "../StyledComponents";
import { BACKGROUNDS, COLORS, ICONS } from "../../extras/constants";
import PopupHeader from "../Popup/PopupHeader";
import PopupBody from "../Popup/PopupBody";

const JSON_DEFAULT = {
  name: "",
  parent: -1,
};

const RowText = styled(Row)`
  margin: 1.2rem 0;
`;

const Categories = ({ onChange, onSubmit, categoriesSelected = [] }) => {
  const dispatch = useDispatch();
  const categories = useSelector(categorySelectors.getCategories);
  const [selected, setSelected] = useState(categoriesSelected);
  const [category, setCategory] = useState(JSON_DEFAULT);

  const handleChanges =
    (name) =>
    ({ target: { value } }) => {
      const changedCategory = { ...category, [name]: value };
      setCategory(changedCategory);
    };

  const handleSuccess = () => {
    setCategory(JSON_DEFAULT);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (category.name)
      dispatch(categoryActions.create(category, handleSuccess));
  };

  const handleSelect = (list) => {
    if (typeof onChange === "function") onChange(list);
    setSelected(list);
  };

  const handleSubmit = () => {
    if (typeof onSubmit === "function") onSubmit();
  };

  useEffect(() => {
    if (!categories.length) dispatch(categoryActions.list({ per_page: 100 }));
  }, []);

  return (
    <>
      <PopupHeader text="Categorías" />
      <PopupBody>
        <RowText>
          <Text
            onChange={handleChanges("name")}
            value={category.name}
            placeholder="Ingrese el nombre de una nueva categoría"
          />
        </RowText>
        <Combobox onChange={handleChanges("parent")} value={category.parent}>
          <option value="-1">--- CATEGORÍA PADRE ---</option>
          {recursiveOptionsMap(categories, 0)}
        </Combobox>
        <Button onClick={handleCreate}>Añadir nueva categoría</Button>
        <Row>
          <BubbleContainer>
            <Column>
              <RecursiveList
                selectedOptions={selected}
                options={categories}
                onChange={handleSelect}
                disabledColor={COLORS.activeButton}
              />
            </Column>
          </BubbleContainer>
        </Row>
        {onSubmit ? <Button onClick={handleSubmit}>Confirmar</Button> : null}
      </PopupBody>
    </>
  );
};

export default Categories;
