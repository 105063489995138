import Bubble from "./Bubble";

const someSelected = (itemA, idField) => (itemB) => {
  if (typeof itemA === "object" && typeof itemB === "object") {
    return itemA[idField] === itemB[idField];
  }
  if (typeof itemA === "object" && typeof itemB === "string") {
    return itemA[idField] === itemB;
  }
  if (typeof itemA === "string" && typeof itemB === "object") {
    return itemA === itemB[idField];
  }
  if (typeof itemA === "string" && typeof itemB === "string") {
    return itemA === itemB;
  }
  return false;
};

const BubbleList = ({
  items = [],
  selectedItems = [],
  onSelect,
  onDelete,
  labelField = "name",
  idField = "id",
}) => {
  return (
    <>
      {items.map((item, index) => (
        <Bubble
          key={`${item}_${index}`}
          value={item}
          selected={selectedItems.some(someSelected(item, idField))}
          onSelect={onSelect}
          onDelete={onDelete}
          labelField={labelField}
        />
      ))}
    </>
  );
};

export default BubbleList;
