import styled from "styled-components";
import { COLORS, ICONS } from "../extras/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid ${COLORS.inactiveEdge} 1px;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  margin-left: 15px;
  margin-bottom: 3px;
  margin-right: 15px;
  color: ${COLORS.inactiveEdge};
  cursor: pointer;
  overflow: hidden;
`;
const ImageIcon = styled.img`
  filter: invert(100%);
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
`;
const ImagePickerList = ({ images = [], onAdd, onRemove }) => {
  return (
    <Container>
      <ImageContainer
        onClick={() => {
          onAdd();
        }}
      >
        <ImageIcon src={ICONS.plus} width="20px" alt="Agregar foto" />
      </ImageContainer>
      {images.map(({ source_url, src, ...image }, index) => (
        <ImageContainer key={`imglist_${index}`}>
          <Image src={source_url || src} {...image} />
        </ImageContainer>
      ))}
    </Container>
  );
};

export default ImagePickerList;
