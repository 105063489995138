import styled from "styled-components";
import Tag from "../../components/Tag";

import { PageContainer } from "../../components/StyledComponents";

const Container = styled(PageContainer)`
  padding: 0;
  overflow: hidden;
`;

const TagPage = () => (
  <Container>
    <Tag />
  </Container>
);

export default TagPage;
