import Attribute from "../../components/Attribute";

const PageAttribute = () => {
  return (
    <>
      <h1>Atributos</h1>
      <Attribute />
    </>
  );
};

export default PageAttribute;
