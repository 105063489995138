import * as API from "./index";

const REPORTS_ENDPOINT = "/wc/v3/reports";

export const getSales = () => API.getJSONData(`${REPORTS_ENDPOINT}/sales`);

export const getProductTotal = () =>
  API.getJSONData(`${REPORTS_ENDPOINT}/products/totals`);

export const getTopSellers = () =>
  API.getJSONData(`${REPORTS_ENDPOINT}/top_sellers`);

export const getCustomerTotal = () =>
  API.getJSONData(`${REPORTS_ENDPOINT}/customers/totals`);
