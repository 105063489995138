import icon_plus from "../assets/images/icon_plus.svg";
import icon_image from "../assets/images/icon_image.svg";
import icon_check from "../assets/images/icon_check.svg";
import icon_upload from "../assets/images/icon_upload.svg";
import icon_delete from "../assets/images/icon_delete.svg";
import icon_size from "../assets/images/icon_size.svg";
/* Iconos Menu */
import icon_menu from "../assets/images/icon_menu.svg";
import icon_arrow from "../assets/images/icon_arrow.svg";
/* Backgrounds */
import background_texture from "../assets/images/background_texture.svg";
/* Woome Icon */
import icon_woome from "../assets/images/icon_woome.svg";

const COLORS = {
  inactiveEdge: "#CBCBCB",
  activeEdge: "#3475D6",
  inactiveButton: "#AFAFAF",
  activeButton: "#0088FF",
  activeSecundaryButton: "#256DD8",
  checked: "#54B949",
  backgroundUploadImage: "#C8E2FF",
  uploadImageBackgroundButton: "#5D86B2",
  deleteButton: "#F15C5C",
  editButton: "#0088FF",
  /* Colores Menu */
  inactiveMenuIcon: "#5F6B8F",
  inactiveMenuText: "#AEB8D5",
  activeMenuIcon: "#FFFFFF",
  activeMenuText: "#FFFFFF",
  activeMenuOption: "#0088FF",
  backgroundMenu: "#282D3C",
  /* Dashboard y listados */
  dashboardBackground: "#F2F5F8",
  tableHeaderBackground: "#0088FF",
  tableHeaderColor: "#FFFFFF",
  tableRowBackground: "#FFFFFF",
  /* Bubble Colors */
  activeBubble: "#17cc03",
  inactiveBubble: "#3475D6",
  /* Selectable Item Color */
  activeSelectableItem: "#17cc03",
  inactiveSelectableItem: "#5f6b8f",
  /* ShowAttributes colors */
  borderShowAttribute: "#0088ff",
  popupHeaderBackground: "#1357BC",
  popupHeaderColor: "white",
  /* Alerts colors */
  backgroundError: "#5F6B8F",
  /* Star active */
  uncheckedStar: "#AFAFAF",
  checkedStar: "orange",
};

const ICONS = {
  plus: icon_plus,
  image: icon_image,
  check: icon_check,
  upload: icon_upload,
  /* Iconos Menu */
  menu: icon_menu,
  arrow: icon_arrow,
  delete: icon_delete,
  size: icon_size,
  woome: icon_woome,
};

const BACKGROUNDS = {
  texture: background_texture,
};

/* Constantes de WooCommerce */
const PUBLICATION_STATUS = {
  publish: "publish",
  draft: "draft",
  pending: "pending",
  private: "private",
};

export { COLORS, ICONS, PUBLICATION_STATUS, BACKGROUNDS };
