import * as API from "./index";

const PRODUCTS_ENDPOINT = "/wc/v3/products";

export const getProduct = (identify) =>
  API.getJSONData(`${PRODUCTS_ENDPOINT}/${identify}`);

export const getProducts = ({ page = 1, per_page = 10, ...rest }) =>
  API.getJSONData(
    `${PRODUCTS_ENDPOINT}${API.getQueryFromObj({ page, per_page, ...rest })}`
  );

export const createProduct = async (product) =>
  API.getJSONData(PRODUCTS_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(product),
  });

export const updateProduct = ({ identify, ...params }) =>
  API.getJSONData(`${PRODUCTS_ENDPOINT}/${identify}`, {
    method: "PUT",
    body: JSON.stringify(params),
  });

export const deleteProduct = (identify, force = false) =>
  API.getJSONData(`${PRODUCTS_ENDPOINT}/${identify}?force=${force}`, {
    method: "DELETE",
  });

export const batchProduct = (operations) =>
  API.getJSONData(`${PRODUCTS_ENDPOINT}/batch`, {
    method: "POST",
    body: JSON.stringify(operations),
  });
