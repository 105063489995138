import { useState } from "react";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";
import { COLORS, ICONS } from "../../extras/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../reducers/user";
import { user as userActions } from "../../actions";
import { CenterColumn, LoginInput, Row } from "../../components/StyledComponents";
import Welcome from "../Welcome";

const JSON_DEFAULT = { email: "", password: "" };

const Icon = styled.div`
  font-size: 1rem;
  color: #1c1e45;
  width: 90%;
  text-align: left;
  margin-top: 3rem;
`;

const TitlePrincipal = styled.h1`
  font-size: 2em;
  color: #1c1e45;
  font-weight: bolder;
  margin-top: 4rem;
`;

const TitleDes = styled.h2`
  font-size: 0.8em;
  color: #aeb8d5;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Pass = styled.p`
  font-size: 0.7em;
  color: #5f6b8f;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: right;
  width: 74%;
`;

const Ok = styled.button`
  font-size: 0.8em;
  color: white;
  font-weight: 600;
  display: flex;
  background: #cbcbcb;
  width: 75%;
  margin: 20px 15px;
  padding: 15px 20px;
  justify-content: center;
  border-radius: 5px;
  border: none;
  margin-top: 10rem;
  background-color: ${({ disabled }) =>
    disabled ? COLORS.inactiveButton : COLORS.activeButton};
  cursor: pointer;
`;

const NoCount = styled.span`
  font-size: 0.9em;
  color: #5f6b8f;
  font-weight: 550;
  margin: 1.2rem;
`;

const Register = styled(Link)`
  font-size: 0.9em;
  color: #0088ff;
  font-weight: bold;
`;

const Login = () => {
  const isLogged = useSelector(selectors.isLogged);
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON_DEFAULT);

  const handleChanges =
    (name) =>
    ({ target: { value } }) => {
      const changedUser = { ...user, [name]: value };
      setUser(changedUser);
    };

  const handleSubmit = () => {
    if (user.email && user.password) {
      dispatch(userActions.login(user));
    } else {
      alert("Debe ingresar usuario y contraseña para continuar");
    }
  };

  return isLogged ? (
    <Redirect to="/" />
  ) : (
    <Row>
      <CenterColumn>
        <Icon>
          <img src={ICONS.woome} />
        </Icon>
        <TitlePrincipal>Ingresar</TitlePrincipal>
        <TitleDes>Usuario, contraseña y listo! A disfrutar de WOOME</TitleDes>
        <LoginInput
          placeholder="EMAIL"
          value={user.email}
          onChange={handleChanges("email")}
        />
        <LoginInput
          placeholder="PASSWORD"
          value={user.password}
          onChange={handleChanges("password")}
          type="password"
        />
        <Pass>¿Olvidaste tu contraseña?</Pass>
        <Ok onClick={handleSubmit} disabled={!(user.email && user.password)}>
          Ingresar
        </Ok>
        <div>
          <NoCount>¿No tienes una cuenta?</NoCount>
          <Register to="/register">Registrarme</Register>
        </div>
      </CenterColumn>
      <Welcome
        title="Bienvenido!"
        description="Lorem lorem lorem lorem lorem"
      />
    </Row>
  );
};

export default Login;
