import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { attribute as attributeActions } from "../../actions";
import { selectors as attributeSelectors } from "../../reducers/attribute";
import {
  Button,
  BubbleContainer,
  Column,
  Row,
  Text,
} from "../StyledComponents";
import BubbleList from "../BubbleList";
import { COLORS } from "../../extras/constants";
import SelectableColumnList from "../SelectableColumnList";

const Header = styled.div`
  background: ${COLORS.activeMenuOption};
  flex: 1;
  padding: 1rem 3rem;
  color: white;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  margin: 1rem;
  font-size: 1.1rem;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  padding: 2rem 3rem;
  border-radius: 1rem;
  margin: 1rem;
  overflow-y: scroll;
  min-height: 70vh;
  max-height: 70vh;
`;

const Confirm = styled.button`
  background: #54b949;
  cursor: pointer;
  width: 100%;
  padding: 1.1rem 1.2rem;
  color: white;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  border: none;
  font-size: 1.3rem;
  margin-top: 2em;
`;

const Attribute = ({ onChange }) => {
  const [termName, setTermName] = useState("");
  const [terms, setTerms] = useState([]);
  /* Para poder realizar operaciones batch de los términos de atributos */
  const [deleteOperations, setDeleteOperations] = useState([]);
  const [createOperations, setCreateOperations] = useState([]);

  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [attributeName, setAttributeName] = useState("");

  const dispatch = useDispatch();
  const attributes = useSelector(attributeSelectors.getAttributes);

  useEffect(() => {
    dispatch(attributeActions.list());
  }, []);

  const handleTermNameChange = (e) => {
    setTermName(e.target.value);
  };
  const handleAttributeNameChange = (e) => {
    setAttributeName(e.target.value);
  };
  const handleDeleteTerm = (term) => {
    if (terms.includes(term)) {
      setTerms(terms.filter((item) => term !== item));
      /* Para agregar los términos que se van a borrar y quitar de la lista de crear */
      if (term.id) {
        deleteOperations.push(term.id);
        setDeleteOperations([...deleteOperations]);
      } else {
        setCreateOperations(
          createOperations.filter((item) => term.name !== item.name)
        );
      }
    }
  };
  const handleAddTermClick = () => {
    if (termName && !terms.some((trm) => trm.name === termName)) {
      const arr = [...terms];
      arr.push({ name: termName });
      setTermName("");
      setTerms(arr);
      /* Para agregar los términos que se van a crear */
      createOperations.push({ name: termName });
      setCreateOperations([...createOperations]);
    }
  };
  const setDefaultValues = () => {
    setSelectedAttribute(null);
    setTerms([]);
    setTermName("");
    setAttributeName("");
  };
  const handleSelectAttribute = (attribute) => {
    if (selectedAttribute && selectedAttribute.id === attribute.id) {
      setDefaultValues();
    } else {
      setTerms([...attribute.terms]);
      setTermName("");
      setSelectedAttribute(attribute);
      setAttributeName(attribute.name);
    }
  };
  const handleDeleteAttribute = (attribute) => {
    dispatch(attributeActions.delete(attribute.id, handleSuccess));
  };
  const handleSuccess = () => {
    setDefaultValues();
    if (typeof onChange === "function") onChange();
  };
  const handleSubmit = () => {
    if (!selectedAttribute && attributeName) {
      dispatch(
        attributeActions.create({ name: attributeName, terms }, handleSuccess)
      );
    } else if (attributeName) {
      dispatch(
        attributeActions.update(
          {
            identify: selectedAttribute.id,
            name: attributeName,
            termsOperations: {
              create: createOperations,
              delete: deleteOperations,
            },
          },
          handleSuccess
        )
      );
    }
  };
  return (
    <>
      <Row>
        <Header> Atributos Existentes </Header>
        <Header>
          {selectedAttribute ? " Modificar Atributo " : " Añadir Atributo "}
        </Header>
      </Row>
      <Row backgroundcolor="transparent">
        <Content>
          <SelectableColumnList
            items={attributes}
            selectedItems={[selectedAttribute]}
            onSelect={handleSelectAttribute}
            onDelete={handleDeleteAttribute}
            idField="id"
          />
        </Content>
        <Content>
          <Row>
            <Text
              placeholder="Nombre del atributo"
              value={attributeName}
              onChange={handleAttributeNameChange}
            />
          </Row>
          <Row>
            <Text
              placeholder="Ingresa los valores aquí"
              value={termName}
              onChange={handleTermNameChange}
            />
            <Button onClick={handleAddTermClick}>Añadir valor</Button>
          </Row>
          <BubbleContainer>
            <BubbleList items={terms} onDelete={handleDeleteTerm} />
          </BubbleContainer>
          <Confirm onClick={handleSubmit}> Confirmar </Confirm>
        </Content>
      </Row>
    </>
  );
};

export default Attribute;
