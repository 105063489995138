/**
 * Compara si los objetos tienen diferencia (no compara de forma profunda, solo el primer nivel)
 * @param Object Primer objeto a comprar
 * @param Object Segundo objeto a comprar
 * @returns
 */
export const compareOb = (a, b) => {
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  if (keysA.length !== keysB.length) return false;
  for (let i = 0; i < keysA.length; i++) {
    let bool = false;
    for (let j = 0; j < keysB.length; j++) {
      if (keysA[i] === keysB[j]) {
        if (a[keysA[i]] !== b[keysB[j]]) bool = false;
        else bool = true;
        break;
      }
    }
    if (!bool) return false;
  }
  return true;
};

/**
 *
 * Devuelve un objeto con los datos que sean diferentes del segundo con respecto al primero.
 *
 *
 * @param Object Primer objeto a comprar
 * @param Object Segundo objeto a comprar
 * @returns
 */
export const getDifference = (a, b) => {
  const differences = {};
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  if (keysA.length !== keysB.length)
    throw Error("Los objetos deben tener la misma cantidad de elementos");
  for (let i = 0; i < keysA.length; i++) {
    for (let j = 0; j < keysB.length; j++) {
      if (keysA[i] === keysB[j]) {
        // Cuando encuentro las keys iguales comparo sus valores
        if (a[keysA[i]] !== b[keysB[j]]) differences[keysB[j]] = b[keysB[j]]; // Si son diferentes lo añado al objeto
        break;
      }
    }
  }
  return differences;
};
