import styled from "styled-components";
import CollapsibleContainer from "../../../components/CollapsibleContainer";
import {
  Row as BubbleContainer,
  Button as ButtonGeneral,
} from "../../../components/StyledComponents";
import { COLORS } from "../../../extras/constants";

const Button = styled(ButtonGeneral)`
  cursor: default;
  margin: 0 10px 0 0;
`;

const DeleteButton = styled(ButtonGeneral)`
  height: auto;
  margin: 1px 5px 1px 1px;
  background-color: ${COLORS.deleteButton};
`;

const ShowAttributes = ({ name, options, id, onDelete }) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    if (typeof onDelete === "function") onDelete(id);
  };
  return (
    <CollapsibleContainer
      title={name}
      color={COLORS.borderShowAttribute}
      titleColor={"white"}
      HeaderButton={() => <DeleteButton onClick={handleDelete}>X</DeleteButton>}
    >
      <BubbleContainer>
        {options.map((option) => (
          <Button key={option}>{option}</Button>
        ))}
      </BubbleContainer>
    </CollapsibleContainer>
  );
};

export default ShowAttributes;
