import styled from "styled-components";
import { CloseButton, DarkBackground, PopupContainer } from "../StyledComponents";

const Container = styled.div`
  position: relative;
  display: flex;
  background-color: white;
  border-radius: 30px;
  ${({ fullscreen, height, width }) =>
    fullscreen
      ? `
  max-height: 90vh;
  max-width: 90vw;
  min-width: 90vw;
  `
      : `
  min-height: ${height};
  min-width: ${width};
  max-height: ${height};
  max-width: ${width};`}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  align-self: stretch;
  border-radius: 30px;
  overflow-y: scroll;
`;

// TODO: Utilizar display none en lugar de desaparecer el componente completamente (genera bugs arreglar eso, por eso se pospone)

const Popup = ({
  onClose,
  children,
  visible = false,
  fullscreen = false,
  height = "95vh",
  width = "60vw",
}) => {
  const handleClose = () => {
    if (typeof onClose === "function") onClose();
  };

  return visible ? (
    <>
      <DarkBackground />
      <PopupContainer>
        <Container fullscreen={fullscreen} height={height} width={width}>
          <CloseButton onClick={handleClose}>X</CloseButton>
          <Wrapper>{children}</Wrapper>
        </Container>
      </PopupContainer>
    </>
  ) : null;
};

export default Popup;
