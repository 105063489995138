import { actions } from "../reducers/product";
import { actions as errorActions } from "../reducers/error";
import * as products from "../api/product";

const productActions = {
  get: (identify) => async (dispatch) => {
    dispatch(actions.getRequest());
    try {
      const result = await products.getProduct(identify);
      if (result.status === "ok")
        dispatch(actions.getSuccess(result.data ? result.data : null));
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  list:
    (props = {}) =>
    async (dispatch) => {
      dispatch(actions.listRequest());
      try {
        const result = await products.getProducts(props);
        if (result.status === "ok")
          dispatch(
            actions.listSuccess(
              result.data ? result.data : [],
              result.pagination
            )
          );
        else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.listFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  create:
    (
      {
        name,
        type = "simple",
        description,
        sku,
        regular_price = 0,
        sale_price,
        ...rest
      },
      handleSuccess
    ) =>
    async (dispatch) => {
      dispatch(actions.createRequest());
      try {
        const result = await products.createProduct({
          name,
          type,
          description,
          sku,
          regular_price,
          sale_price,
          ...rest,
        });
        if (result.status === "ok") {
          dispatch(actions.createSuccess(result.data ? result.data : []));
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.createFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  update:
    ({ identify, ...rest }, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.updateRequest());
      try {
        if (!identify) throw Error("Identificador de producto inválido");
        const result = await products.updateProduct({ identify, ...rest });
        if (result.status === "ok") {
          dispatch(
            actions.updateSuccess(
              result.data ? result.data : [],
              "Actualización exitosa"
            )
          );
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.updateFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  delete: (identify, handleSuccess) => async (dispatch) => {
    dispatch(actions.deleteRequest());
    try {
      const result = await products.deleteProduct(identify);
      if (result.status === "ok") {
        dispatch(actions.deleteSuccess(result.data ? result.data : "Correcto"));
        handleSuccess && handleSuccess(result.data);
      } else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.deleteFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  batch:
    (operations = {}, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.batchRequest());
      try {
        const result = await products.batchProduct(operations);
        if (result.status === "ok") {
          dispatch(
            actions.batchSuccess(result.data ? result.data : "Correcto")
          );
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.batchFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  clearProduct: () => (dispatch) => {
    dispatch(actions.clearProduct());
  },
};

export default productActions;
