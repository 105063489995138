import { useState } from "react";
import styled from "styled-components";
import { Text } from "../StyledComponents";
import TableCell from "./TableCell";

const EditableText = styled(Text)`
  margin: 0;
`;

const EditableCell = ({ label, value, onChange, flexValue = 1 }) => {
  const [text, setText] = useState(value);
  const handleChanges = ({ target: { value } }) => {
    setText(value);
  };
  const handleBlur = () => {
    setText(value);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (typeof onChange === "function") {
        onChange(!isNaN(text) ? text : value);
      }
    }
  };
  return (
    <TableCell flexValue={flexValue}>
      <label>
        {label}
        <EditableText
          value={text}
          onChange={handleChanges}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </label>
    </TableCell>
  );
};

export default EditableCell;
