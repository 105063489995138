import styled from "styled-components";
import { COLORS } from "../../extras/constants";
import { ReactComponent as Icon } from "../../assets/images/icon_categories.svg";
import { useLocation, Link } from "react-router-dom";
import { IconContext } from "react-icons/lib";

const StyledIcon = styled(Icon)`
  fill: ${({ selected }) =>
    selected ? COLORS.activeMenuText : COLORS.inactiveMenuIcon};
`;

const Button = styled(Link)`
  display: flex;
  padding: 5px 15px;
  margin: 5px 20px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ selected }) =>
    selected ? COLORS.activeMenuOption : "transparent"};
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s;
  color: ${({ selected }) =>
    selected ? COLORS.activeMenuText : COLORS.inactiveMenuText};
  :hover {
    box-shadow: 0 0 0 1px #e8eded;
  }
  width: ${({ open }) => (!open ? "25px" : "160px")};
  overflow: hidden;
  text-decoration: none;
`;

const WrapperContent = styled.div`
  margin-right: 15px;
  color: ${({ selected }) =>
    selected ? COLORS.activeMenuText : COLORS.inactiveMenuText};
`;

const MenuItem = ({ children, url, open = true, Icon }) => {
  const location = useLocation();
  return (
    <Button selected={location.pathname === url} open={open} to={url}>
      <WrapperContent>
        {Icon ? (
          <IconContext.Provider
            value={{
              size: "30px",
              color:
                location.pathname === url
                  ? COLORS.activeMenuText
                  : COLORS.inactiveMenuIcon,
            }}
          >
            <div>
              <Icon />
            </div>
          </IconContext.Provider>
        ) : (
          <StyledIcon selected={location.pathname === url} />
        )}
      </WrapperContent>
      {children}
    </Button>
  );
};

export default MenuItem;
