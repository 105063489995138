import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
// import { createLogger } from 'redux-logger';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

// Reducers
import rootReducer from "./reducers";

const middlewares = [thunk];

// eslint-disable-next-line no-undef
// if (__DEV__) {
//   middlewares.push(createLogger());
// }

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
