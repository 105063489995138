import { actions } from "../reducers/report";
import { actions as errorActions } from "../reducers/error";
import * as reports from "../api/report";

const reportActions = {
  getSales: () => async (dispatch) => {
    dispatch(actions.getSalesRequest());
    try {
      const result = await reports.getSales();
      if (result.status === "ok")
        dispatch(
          actions.getSalesSuccess(
            result.data && result.data.length ? result.data[0] : {}
          )
        );
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getSalesFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  getProductTotal: () => async (dispatch) => {
    dispatch(actions.getProductTotalRequest());
    try {
      const result = await reports.getProductTotal();
      if (result.status === "ok")
        dispatch(
          actions.getProductTotalSuccess(result.data ? result.data : [])
        );
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getProductTotalFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  getTopSellers: () => async (dispatch) => {
    dispatch(actions.getTopSellersRequest());
    try {
      const result = await reports.getTopSellers();
      if (result.status === "ok")
        dispatch(actions.getTopSellersSuccess(result.data ? result.data : []));
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getTopSellersFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  getCustomerTotal: () => async (dispatch) => {
    dispatch(actions.getCustomerTotalRequest());
    try {
      const result = await reports.getCustomerTotal();
      if (result.status === "ok")
        dispatch(
          actions.getCustomerTotalSuccess(result.data ? result.data : [])
        );
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getCustomerTotalFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
};

export default reportActions;
