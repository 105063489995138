import styled from "styled-components";
import { COLORS, ICONS } from "../extras/constants";

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid ${COLORS.inactiveEdge} 1px;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  color: ${COLORS.inactiveEdge};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "initial")};
  overflow: hidden;
  user-select: none;
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const ImagePicker = ({ image, onAdd }) => {
  return (
    <ImageContainer
      onClick={() => {
        onAdd();
      }}
      pointer={!image}
    >
      {image ? (
        <Image src={image.source_url || image.src} alt={image.alt} />
      ) : (
        <>
          <img src={ICONS.image} alt="Ícono de foto" />
          Seleccionar imagen de portada
        </>
      )}
    </ImageContainer>
  );
};

export default ImagePicker;
