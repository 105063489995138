import styled from "styled-components";
import { BACKGROUNDS, COLORS, ICONS } from "../../extras/constants";

const RowHead = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${BACKGROUNDS.texture});
  justify-content: center;
  align-items: center;
  color: ${COLORS.popupHeaderColor};
  background-color: ${COLORS.popupHeaderBackground};
  padding-top: 1rem;
`;

const PopupHeader = ({ text = "" }) => {
  return (
    <RowHead>
      <img src={ICONS.size} alt="" width="80px" />
      <h1>{text}</h1>
    </RowHead>
  );
};

export default PopupHeader;
