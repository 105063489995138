import styled from "styled-components";
import { COLORS } from "../extras/constants";
import Checkbox from "./Checkbox";

const Ul = styled.ul`
  margin: 5px;
  list-style: none;
`;

const someParent = (id) => (cat) => id === cat.parent;
const someSelected = (id) => (cat) => id === cat.id;
const createString = (text, times) => {
  let result = "";
  for (let i = 0; i < times; i++) {
    result += text;
  }
  return result;
};

export const recursiveOptionsMap = (
  categories = [],
  parent = 0,
  result = [],
  level = 0
) => {
  categories.filter(someParent(parent)).forEach(({ id, name }) => {
    result.push(
      <option value={id} key={String(id) + name}>
        {createString("\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0", level)}
        {name}
      </option>
    );
    recursiveOptionsMap(categories, id, result, level + 1);
  });
  return result;
};

/**
 * Necesita que se le pase por parámetro el selected options y el onChange, sino no hace nada
 *
 */
const RecursiveList = ({
  options = [],
  parent = 0,
  onChange,
  selectedOptions = [],
  disabledColor = COLORS.activeButton,
}) => {
  const handleChange = (cat) => (e) => {
    const arr = [...selectedOptions];
    if (arr.some(someSelected(cat.id))) {
      arr.splice(arr.findIndex(someSelected(cat.id)), 1);
    } else {
      arr.push(cat);
    }
    if (typeof onChange === "function") onChange(arr);
  };
  return options.length ? (
    <Ul>
      {options.filter(someParent(parent)).map((option) => (
        <li key={String(option.id) + option.name}>
          <Checkbox
            name={option.name}
            onChange={handleChange(option)}
            checked={selectedOptions.some(someSelected(option.id))}
            label={option.name}
            disabledColor={disabledColor}
          />
          <RecursiveList
            options={options}
            parent={option.id}
            onChange={onChange}
            selectedOptions={selectedOptions}
          />
        </li>
      ))}
    </Ul>
  ) : null;
};

export default RecursiveList;
