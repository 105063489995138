import styled from "styled-components";
import { COLORS } from "../extras/constants";

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1em;
  color: ${({ checked, disabledColor }) =>
    checked ? COLORS.checked : disabledColor};
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 25px;
  border-radius: 5px;
  border: solid 1px ${COLORS.inactiveEdge};
  ${Container}:hover & {
    background-color: ${COLORS.inactiveEdge};
  }

  ${Input}:checked ~ & {
    background-color: ${COLORS.checked};
  }

  :after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked ~ &:after {
    display: block;
  }

  ${Container} &:after {
    left: 9px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Checkbox = ({
  value,
  checked,
  onChange,
  name,
  id,
  disabled,
  label,
  disabledColor = COLORS.inactiveEdge,
}) => {
  const handleCheck = (e) => {
    if (typeof onChange === "function") onChange(e);
  };

  return (
    <Container
      htmlFor={id}
      disabled={disabled}
      checked={checked}
      disabledColor={disabledColor}
    >
      {label}
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={handleCheck}
      />
      <Checkmark />
    </Container>
  );
};

export default Checkbox;
