import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../reducers/error";
import {
  CardContainer,
  CloseButton,
  DarkBackground,
  PopupContainer,
} from "./StyledComponents";
import { COLORS } from "../extras/constants";
import { IconContext } from "react-icons/lib";
import { MdWarning } from "react-icons/md";

const Container = styled(CardContainer)`
  background: ${COLORS.backgroundError};
`;

const ErrorNotification = () => {
  const isOpen = useSelector(selectors.isOpen);
  const error = useSelector(selectors.getError);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(actions.hideError());
  };

  return isOpen && error ? (
    <>
      <DarkBackground />
      {
        <PopupContainer>
          <Container>
            <CloseButton onClick={handleClose}>X</CloseButton>
            <IconContext.Provider
              value={{
                size: "80px",
                color: COLORS.activeMenuText,
              }}
            >
              <MdWarning />
            </IconContext.Provider>
            <h2>{error}</h2>
          </Container>
        </PopupContainer>
      }
    </>
  ) : null;
};

export default ErrorNotification;
