import { actions } from "../reducers/category";
import { actions as errorActions } from "../reducers/error";
import * as categories from "../api/category";

const categoryActions = {
  get: (identify) => async (dispatch) => {
    dispatch(actions.getRequest());
    try {
      const result = await categories.getCategory(identify);
      if (result.status === "ok")
        dispatch(actions.getSuccess(result.data ? result.data : null));
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  list:
    (props = {}) =>
    async (dispatch) => {
      dispatch(actions.listRequest());
      try {
        const result = await categories.getCategories(props);
        if (result.status === "ok")
          dispatch(
            actions.listSuccess(
              result.data ? result.data : [],
              result.pagination
            )
          );
        else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.listFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  create:
    ({ name, parent = 0, ...rest }, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.createRequest());
      try {
        const result = await categories.createCategory({
          name,
          parent,
          ...rest
        });
        if (result.status === "ok") {
          dispatch(actions.createSuccess(result.data ? result.data : []));
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.createFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  update:
    ({ identify, ...rest }, handleSuccess) =>
    async (dispatch) => {
      // dispatch(actions.updateRequest());
      // try {
      //   if (!identify) throw Error("Identificador de categoría inválido");
      //   const result = await categories.updateCategory({ identify, ...rest });
      //   if (result.status === "ok") {
      //     dispatch(
      //       actions.updateSuccess(
      //         result.data ? result.data : [],
      //         "Actualización exitosa"
      //       )
      //     );
      //     handleSuccess && handleSuccess(result.data);
      //   } else throw Error(result.data);
      // } catch (ex) {
      //   dispatch(actions.updateFailure(ex.message));
      //   dispatch(errorActions.setError(ex.message));
      // }
    },
  delete: (identify, handleSuccess) => async (dispatch) => {
    // dispatch(actions.deleteRequest());
    // try {
    //   const result = await categories.deleteCategory(identify);
    //   if (result.status === "ok") {
    //     dispatch(actions.deleteSuccess(result.data ? result.data : "Correcto"));
    //     handleSuccess && handleSuccess(result.data);
    //   } else throw Error(result.data);
    // } catch (ex) {
    //   dispatch(actions.deleteFailure(ex.message));
    //   dispatch(errorActions.setError(ex.message));
    // }
  },
  batch:
    (operations = {}, handleSuccess) =>
    async (dispatch) => {
      // dispatch(actions.batchRequest());
      // try {
      //   const result = await categories.batchCategory(operations);
      //   if (result.status === "ok") {
      //     dispatch(
      //       actions.batchSuccess(result.data ? result.data : "Correcto")
      //     );
      //     handleSuccess && handleSuccess(result.data);
      //   } else throw Error(result.data);
      // } catch (ex) {
      //   dispatch(actions.batchFailure(ex.message));
      //   dispatch(errorActions.setError(ex.message));
      // }
    },
};

export default categoryActions;
