import { actions } from "../reducers/media";
import { actions as errorActions } from "../reducers/error";
import * as medias from "../api/media";

export default {
  list:
    (props = {}) =>
    async (dispatch) => {
      dispatch(actions.listRequest());
      try {
        const result = await medias.getMedias(props);
        if (result.status === "ok")
          dispatch(
            actions.listSuccess(
              result.data ? result.data : [],
              result.pagination
            )
          );
        else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.listFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  create:
    ({ name, file }, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.createRequest());
      try {
        const result = await medias.createMedia({ name, file });
        if (result.status === "ok") {
          dispatch(actions.createSuccess(result.data ? result.data : {}, "Imagen creada correctamente"));
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.createFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
};
