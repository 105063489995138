import styled from "styled-components";
import { Link } from "react-router-dom";
import { BACKGROUNDS, COLORS } from "../extras/constants";

const Divider = styled.hr`
  width: 100%;
  color: ${COLORS.inactiveEdge};
  border-width: 1px;
  border-color: white;
  ${({ customCSS = "" }) => customCSS}
`;

const ConfirmButton = styled.a`
  display: flex;
  padding: 0.8rem 2rem;
  margin: 1.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundcolor = COLORS.checked }) =>
    backgroundcolor};
  border: solid 1px ${COLORS.inactiveEdge};
  border-radius: 0.5rem;
  user-select: none;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  :hover {
    box-shadow: inset 0 0 0 1px ${COLORS.activeButton};
  }
`;

// TODO create general button design and redesign it in particulars cases

const Button = styled.a`
  display: inline-flex;
  color: white;
  height: 1.8rem;
  font-weight: bold;
  padding: ${({ padding }) => (padding ? padding : ".5rem 1.5rem")};
  margin: ${({ margin }) => (margin ? margin : "1.5rem")};
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundcolor }) =>
    backgroundcolor ? backgroundcolor : COLORS.activeSecundaryButton};
  border-radius: 0.5rem;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s;
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : "auto")};
  :hover {
    box-shadow: 0 0 0 3px #e8eded;
  }
`;

const LinkButton = styled(Link)`
  display: flex;
  padding: ${({ padding }) => (padding ? padding : ".5rem 1.5rem")};
  margin: ${({ margin }) => (margin ? margin : "1.5rem")};
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundcolor }) =>
    backgroundcolor ? backgroundcolor : "white"};
  border-radius: 0.5rem;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s;
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : "auto")};
  :hover {
    box-shadow: 0 0 0 3px #e8eded;
  }
  text-decoration: none;
  color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: ${({ flexValue = 1 }) => flexValue};
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${({ flexValue = 1 }) => flexValue};
  background-color: ${({ backgroundcolor = "white" }) => backgroundcolor};
  align-items: stretch;
  justify-content: center;
  align-self: stretch;
`;

const CenterColumn = styled(Column)`
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding-bottom: 2rem;
`;

const PageContainer = styled.div`
  background: white;
  width: 70vw;
  padding: 3rem 4rem;
  margin: 1.5rem 0;
  border-radius: 2rem;
  border: 1px solid #e5e7ec;
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* backdrop-filter: blur(2px); */
  z-index: 2;
`;

const PopupContainer = styled.div`
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  background: transparent;
  width: 100vw;
  height: 100vh;
`;

const CloseButton = styled.div`
  position: absolute;
  top: -0.5rem;
  right: 0;
  width: 3rem;
  height: 3rem;
  background-color: black;
  color: white;
  font-size: 2rem;
  text-align: center;
  border-radius: 1.5rem;
  cursor: pointer;
`;

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.activeButton};
  color: white;
  padding: 4rem;
  border-radius: 3rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
  min-width: 30rem;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1;
  align-self: stretch;
  border: solid 1px ${COLORS.inactiveEdge};
  border-radius: 1.5rem;
  padding: 0.5rem;
  min-height: 30vh;
  max-height: 30vh;
  overflow-y: scroll;
`;

const FieldHeader = styled.h4``;

const Text = styled.input`
  display: inline;
  font-size: 1.2rem;
  min-height: 2rem;
  max-height: 4rem;
  background-color: white;
  border: none;
  border-bottom: solid ${COLORS.inactiveEdge} 1px;
  outline: none;
  text-align: left;
  flex: ${({ flex = 1 }) => flex};
  opacity: 0.3;
  :focus {
    opacity: 1;
    border-bottom-color: ${COLORS.activeEdge};
  }
  margin-bottom: 2rem;
`;

const Combobox = styled.select`
  display: inline;
  max-height: 2.8rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  align-self: stretch;
  flex: 1;
`;

const LoginInput = styled.input`
  background: #ffffff;
  border: 1px solid #e5e7ec;
  border-radius: 5px;
  width: 70%;
  height: 2rem;
  margin: 0.5rem 1rem;
  padding: 0.8rem 1.2rem;
`;

export {
  Divider,
  ConfirmButton,
  Button,
  Row,
  Column,
  LinkButton,
  PageContainer,
  DarkBackground,
  PopupContainer,
  CloseButton,
  CardContainer,
  BubbleContainer,
  FieldHeader,
  Text,
  Combobox,
  LoginInput,
  CenterColumn
};
