import { useEffect, useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { MdDelete, MdEdit } from "react-icons/md";
import { product as productActions } from "../../actions";
import { selectors as productSelectors } from "../../reducers/product";
import { useSelector, useDispatch } from "react-redux";
import { COLORS, ICONS, PUBLICATION_STATUS } from "../../extras/constants";
import PagintationControl from "../../components/PaginationControl";
import EditableCell from "../../components/Table/EditableCell";
import { Button, LinkButton } from "../../components/StyledComponents";
import Checkbox from "../../components/Checkbox";
import TableRowHead from "../../components/Table/TableRowHead";
import TableCellHead from "../../components/Table/TableCellHead";
import TableContainer from "../../components/Table/TableContainer";
import TableRow from "../../components/Table/TableRow";
import TableCell from "../../components/Table/TableCell";
import TextCell from "../../components/Table/TextCell";
import StarCheckbox from "../../components/StarCheckbox";

const OneTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #1c1e45;
`;

const ContainerTop = styled.div`
  width: 100%;
  display: flex;
`;

const ContainerSearch = styled.div`
  width: 50%;
  justify-content: right;
  display: flex;
  padding: 0.8rem;
  align-items: center;
`;

const SearchInput = styled.input`
  background: #ffffff;
  border: 1px solid #e5e7ec;
  border-radius: 5px;
  width: 25rem;
  height: 3rem;
`;

const StockStatus = styled.span`
  color: ${({ color }) => color};
`;

const List = () => {
  const dispatch = useDispatch();
  const products = useSelector(productSelectors.getProducts);
  const pagination = useSelector(productSelectors.getPagination);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");

  const handlePagination = (number) => {
    setSelected([]);
    setPage(number);
  };

  const loadProducts = () => {
    let params = {};
    params.page = page;
    if (search) params.search = search;
    dispatch(productActions.list(params));
    setSelected([]);
  };

  const handleDelete = (identify) => () => {
    if (window.confirm("¿Está seguro de eliminar el este producto?")) {
      dispatch(productActions.delete(identify, loadProducts));
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) setSelected(products.map(({ id }) => id));
    else setSelected([]);
  };

  const handleSelect = (identify) => () => {
    if (selected.includes(identify)) {
      setSelected(selected.filter((id) => id !== identify));
    } else {
      selected.push(identify);
      setSelected([...selected]);
    }
  };

  const handleDeleteBatch = () => {
    if (selected.length) {
      if (
        window.confirm(`¿Está seguro de eliminar ${selected.length} productos?`)
      ) {
        const operations = {};
        operations.delete = selected;
        dispatch(productActions.batch(operations, loadProducts));
      }
    }
  };

  useEffect(() => {
    loadProducts();
  }, [page]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = (e) => {
    dispatch(productActions.list({ search }));
  };

  const handlePrice = (identify) => (regular_price) => {
    dispatch(productActions.update({ identify, regular_price }));
  };

  const handleFeatured = (identify) => (e) => {
    dispatch(productActions.update({ identify, featured: e.target.checked }));
  };

  const handlePublish = (identify) => () => {
    dispatch(
      productActions.update({ identify, status: PUBLICATION_STATUS.publish })
    );
  };

  const handleUnpublish = (identify) => () => {
    dispatch(
      productActions.update({ identify, status: PUBLICATION_STATUS.private })
    );
  };

  return (
    <>
      <OneTitle>Listado de Productos</OneTitle>
      <ContainerTop>
        <LinkButton
          backgroundcolor={COLORS.activeSecundaryButton}
          to="/product/create"
        >
          Crear Producto
        </LinkButton>
        <ContainerSearch>
          <SearchInput
            type="search"
            value={search}
            onChange={handleSearchChange}
          />
          <Button onClick={handleSearchClick}>BUSCAR</Button>
        </ContainerSearch>
      </ContainerTop>
      <TableContainer>
        <TableRowHead>
          <TableCellHead flexValue={1}>
            <Checkbox
              onChange={handleSelectAll}
              checked={selected.length === products.length}
            />
          </TableCellHead>
          <TableCellHead flexValue={1}></TableCellHead>
          <TableCellHead flexValue={2}>Imagen</TableCellHead>
          <TableCellHead flexValue={5}>Nombre</TableCellHead>
          <TableCellHead flexValue={4}>SKU</TableCellHead>
          <TableCellHead flexValue={2}>Stock</TableCellHead>
          <TableCellHead flexValue={3}>Precio</TableCellHead>
          <TableCellHead flexValue={5}>Categorías</TableCellHead>
          <TableCellHead flexValue={3}>Fecha</TableCellHead>
          <TableCellHead flexValue={4}>Estado</TableCellHead>
          <TableCellHead flexValue={4}></TableCellHead>
        </TableRowHead>
        {products.map(
          ({
            id,
            name,
            images,
            sku,
            stock_quantity,
            price,
            categories,
            date_created,
            featured,
            stock_status,
            status,
          }) => (
            <TableRow key={String(id) + name}>
              <TableCell flexValue={1}>
                <Checkbox
                  checked={selected.includes(id)}
                  onChange={handleSelect(id)}
                />
              </TableCell>
              <TableCell flexValue={1}>
                <StarCheckbox
                  checked={featured}
                  onChange={handleFeatured(id)}
                />
              </TableCell>
              <TableCell flexValue={2}>
                {images && images[0] ? (
                  <img src={images[0].src} width="60px" />
                ) : (
                  <img src={ICONS.image} alt="Ícono de foto" width="60px" />
                )}
              </TableCell>
              <TextCell flexValue={5}><span>{name}</span></TextCell>
              <TableCell flexValue={4}><span>{sku}</span></TableCell>
              <TableCell flexValue={2}>
                {stock_status === "instock" ? (
                  <StockStatus color={COLORS.editButton} title={stock_quantity}>
                    Sí{stock_quantity ? `(${stock_quantity})` : ""}
                  </StockStatus>
                ) : (
                  <StockStatus
                    color={COLORS.deleteButton}
                    title={stock_quantity}
                  >
                    No
                  </StockStatus>
                )}
              </TableCell>
              <EditableCell
                value={price}
                type="number"
                min="0"
                label="$"
                onChange={handlePrice(id)}
                flexValue={3}
              />
              <TextCell flexValue={5}>
                <span>
                  {(() => {
                    let categoriesStr = "";
                    categories.forEach(
                      (item, index) =>
                        (categoriesStr +=
                          item.name +
                          (index < categories.length - 1 ? ", " : ""))
                    );
                    return categoriesStr;
                  })()}
                </span>
              </TextCell>
              <TableCell flexValue={3}>
                <span>
                  {new Date(date_created).toLocaleDateString("uy-UY")}
                </span>
              </TableCell>
              <TableCell flexValue={4}>
                {status !== PUBLICATION_STATUS.publish ? (
                  <Button
                    onClick={handlePublish(id)}
                    backgroundcolor={COLORS.checked}
                    margin="5px"
                    padding="5px"
                  >
                    Publicar
                  </Button>
                ) : (
                  <Button
                    onClick={handleUnpublish(id)}
                    backgroundcolor={COLORS.inactiveEdge}
                    margin="5px"
                    padding="5px"
                  >
                    Despublicar
                  </Button>
                )}
              </TableCell>
              <TableCell flexValue={4}>
                <LinkButton
                  backgroundcolor={COLORS.inactiveMenuIcon}
                  margin="5px"
                  padding="5px"
                  title="Editar"
                  to={`/product/create?duplicate=${id}`}
                >
                  Duplicar
                </LinkButton>
                <LinkButton
                  backgroundcolor={COLORS.editButton}
                  margin="0px"
                  padding="2px 5px"
                  title="Editar"
                  to={`/product/${id}`}
                >
                  <IconContext.Provider
                    value={{ size: "20px", color: "white" }}
                  >
                    <div>
                      <MdEdit />
                    </div>
                  </IconContext.Provider>
                </LinkButton>
                <Button
                  backgroundcolor={COLORS.deleteButton}
                  margin="5px"
                  padding="6px 6px"
                  onClick={handleDelete(id)}
                  title="Eliminar"
                >
                  <IconContext.Provider
                    value={{ size: "20px", color: "white" }}
                  >
                    <div>
                      <MdDelete />
                    </div>
                  </IconContext.Provider>
                </Button>
              </TableCell>
            </TableRow>
          )
        )}
      </TableContainer>
      <Button
        backgroundcolor={COLORS.deleteButton}
        alignSelf="flex-start"
        padding="5px 60px"
        margin="10px 0 0 0"
        onClick={handleDeleteBatch}
      >
        <img src={ICONS.delete} />
      </Button>
      <PagintationControl
        currentpage={page}
        totalpages={pagination ? pagination.totalpages : 0}
        onChange={handlePagination}
      />
    </>
  );
};

export default List;
