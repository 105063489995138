import styled from "styled-components";
import TableCell from "./TableCell";

const TextCell = styled(TableCell)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  /* &:hover {
    overflow: visible;
  } */
`;

export default TextCell;
