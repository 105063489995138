import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { attribute as attributeActions } from "../../../actions";
import { selectors as attributeSelectors } from "../../../reducers/attribute";
import {
  Button,
  Divider,
  Column,
  Row,
  BubbleContainer,
  Combobox,
} from "../../../components/StyledComponents";
import { useEffect, useState } from "react";
import BubbleList from "../../../components/BubbleList";
import CollapsibleContainer from "../../../components/CollapsibleContainer";
import { COLORS } from "../../../extras/constants";
import Popup from "../../../components/Popup";
import Attribute from "../../../components/Attribute";
import PopupHeader from "../../../components/Popup/PopupHeader";
import PopupBody from "../../../components/Popup/PopupBody";

const AttributeContainer = styled(Column)`
  flex: 3;
`;

const EditAttributes = ({ attributes = [], onChange }) => {
  const [productAttributes, setProductAttributes] = useState(attributes);
  const generalAttributes = useSelector(attributeSelectors.getAttributes);
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);
  const [popAttribute, setPopAttribute] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!generalAttributes.length) dispatch(attributeActions.list());
  }, []);
  const handleSelect =
    (id) =>
    ({ name }) => {
      const attribute = productAttributes.find((attr) => attr.id === id);
      if (attribute && attribute.options) {
        const { options } = attribute;
        if (options.some((value) => value === name)) {
          attribute.options = options.filter((value) => value !== name);
        } else {
          options.push(name);
        }
      }
      setProductAttributes([...productAttributes]);
    };
  const handleSubmit = () => {
    if (typeof onChange === "function") {
      onChange(
        productAttributes.filter(({ options }) => options && options.length)
      );
    }
  };
  const handleAdd = () => {
    if (selectedAttributeId) {
      const attribute = generalAttributes.find(
        ({ id }) => String(id) === selectedAttributeId
      );
      if (
        !productAttributes.some(({ id }) => String(id) === selectedAttributeId)
      ) {
        productAttributes.push({
          ...attribute,
          options: [],
        });
        setProductAttributes([...productAttributes]);
      } else {
        alert("Atributo ya seleccionado");
      }
    }
  };
  const handleCboAttribute = ({ target: { value } }) =>
    setSelectedAttributeId(value);

  const handleAddValue =
    ({ id, name, options }) =>
    () => {
      const value = window.prompt("Ingrese el nombre de nuevo valor");
      if (
        value &&
        options.some(
          (option) =>
            option.localeCompare(value, undefined, {
              sensitivity: "base",
            }) === 0
        )
      ) {
        window.alert("El valor ya existe");
      } else if (value) {
        dispatch(
          attributeActions.update(
            {
              identify: id,
              name,
              termsOperations: { create: [{ name: value }] },
            },
            () => {
              window.alert(`${value} ingresado correctamente`);
            }
          )
        );
      }
    };

  return (
    <>
      <Popup
        visible={popAttribute}
        onClose={() => setPopAttribute(false)}
        fullscreen={true}
      >
        <Attribute onChange={() => setPopAttribute(false)} />
      </Popup>
      <PopupHeader text="Atributos" />
      <PopupBody>
        <p>Seleccione los atributos para su producto</p>
        <div>
          <Combobox onChange={handleCboAttribute} defaultValue={-1}>
            <option value={-1} disabled>
              --- Selecciona un Atributo ---
            </option>
            {generalAttributes.map(({ id, name }) => (
              <option value={id} key={`${id}_${name}`}>
                {name}
              </option>
            ))}
          </Combobox>
          <Button onClick={handleAdd}>Añadir</Button>
        </div>
        <AttributeContainer>
          {generalAttributes.length
            ? productAttributes.map(({ id, name, options }) => {
                return (
                  <CollapsibleContainer
                    title={name}
                    key={`${name}_${id}`}
                    color={COLORS.inactiveSelectableItem}
                  >
                    <div>
                      Valores{" "}
                      <Button onClick={handleAddValue({ id, name, options })}>
                        +
                      </Button>
                    </div>
                    <BubbleContainer>
                      <BubbleList
                        items={
                          generalAttributes.find((attr) => attr.name === name)
                            .terms
                        }
                        onSelect={handleSelect(id)}
                        selectedItems={options}
                        idField="name"
                      />
                    </BubbleContainer>
                  </CollapsibleContainer>
                );
              })
            : null}
        </AttributeContainer>
        <Button onClick={() => setPopAttribute(true)}>
          Añadir nuevos atributos
        </Button>
        <Divider />
        <Button onClick={handleSubmit}>Confirmar</Button>
      </PopupBody>
    </>
  );
};

export default EditAttributes;
