export const types = {
  TAG_GET_REQUEST: "tags/TAG_GET_REQUEST",
  TAG_GET_SUCCESS: "tags/TAG_GET_SUCCESS",
  TAG_GET_FAILURE: "tags/TAG_GET_FAILURE",
  TAG_LIST_REQUEST: "tags/TAG_LIST_REQUEST",
  TAG_LIST_SUCCESS: "tags/TAG_LIST_SUCCESS",
  TAG_LIST_FAILURE: "tags/TAG_LIST_FAILURE",
  TAG_CREATE_REQUEST: "tags/TAG_CREATE_REQUEST",
  TAG_CREATE_SUCCESS: "tags/TAG_CREATE_SUCCESS",
  TAG_CREATE_FAILURE: "tags/TAG_CREATE_FAILURE",
  TAG_DELETE_REQUEST: "tags/TAG_DELETE_REQUEST",
  TAG_DELETE_SUCCESS: "tags/TAG_DELETE_SUCCESS",
  TAG_DELETE_FAILURE: "tags/TAG_DELETE_FAILURE",
  TAG_UPDATE_REQUEST: "tags/TAG_UPDATE_REQUEST",
  TAG_UPDATE_SUCCESS: "tags/TAG_UPDATE_SUCCESS",
  TAG_UPDATE_FAILURE: "tags/TAG_UPDATE_FAILURE",
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  error: null,
  pagination: null,
  tags: [],
};

export const actions = {
  getRequest: () => ({
    type: types.TAG_GET_REQUEST,
  }),
  getSuccess: (tag) => ({
    type: types.TAG_GET_SUCCESS,
    tag,
  }),
  getFailure: (error) => ({
    type: types.TAG_GET_FAILURE,
    error,
  }),
  listRequest: () => ({
    type: types.TAG_LIST_REQUEST,
  }),
  listSuccess: (tags) => ({
    type: types.TAG_LIST_SUCCESS,
    tags,
  }),
  listFailure: (error) => ({
    type: types.TAG_LIST_FAILURE,
    error,
  }),
  createRequest: () => ({
    type: types.TAG_CREATE_REQUEST,
  }),
  createSuccess: (tag) => ({
    type: types.TAG_CREATE_SUCCESS,
    tag,
  }),
  createFailure: (error) => ({
    type: types.TAG_CREATE_FAILURE,
    error,
  }),
  deleteRequest: () => ({
    type: types.TAG_DELETE_REQUEST,
  }),
  deleteSuccess: (identify) => ({
    type: types.TAG_DELETE_SUCCESS,
    identify,
  }),
  deleteFailure: (error) => ({
    type: types.TAG_DELETE_FAILURE,
    error,
  }),
  updateRequest: () => ({
    type: types.TAG_UPDATE_REQUEST,
  }),
  updateSuccess: (tag) => ({
    type: types.TAG_UPDATE_SUCCESS,
    tag,
  }),
  updateFailure: (error) => ({
    type: types.TAG_UPDATE_FAILURE,
    error,
  }),
};

export const selectors = {
  isFetching: ({ tag }) => tag.isFetching,
  getMessage: ({ tag }) => tag.message,
  getError: ({ tag }) => tag.error,
  getTags: ({ tag }) => tag.tags,
  getCategory: ({ tag }) => tag.tag,
  getPagination: ({ tag }) => tag.pagination,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TAG_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        tag: null,
      };
    case types.TAG_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        tag: action.tag,
        pagination: action.pagination,
      };
    case types.TAG_GET_FAILURE:
      return {
        ...state,
        tag: null,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.TAG_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        tags: [],
      };
    case types.TAG_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        tags: action.tags,
        pagination: action.pagination,
      };
    case types.TAG_LIST_FAILURE:
      return {
        ...state,
        tags: [],
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.TAG_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
      };
    case types.TAG_CREATE_SUCCESS:
      const tags = [...state.tags];
      tags.push(action.tag);
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        tags,
      };
    case types.TAG_CREATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.TAG_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
      };
    case types.TAG_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        tags: state.tags.filter((tag) => tag.id !== action.identify),
      };
    case types.TAG_DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };

    case types.TAG_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
      };
    case types.TAG_UPDATE_SUCCESS:
      const updatedAttributes = state.attributes.map((attr) => {
        if (attr.id === action.attribute.id) return action.attribute;
        return attr;
      });
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        attributes: updatedAttributes,
      };
    case types.TAG_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
