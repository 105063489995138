import React from "react";
import styled, { keyframes } from "styled-components";

const TickMark = ({ successText="¡Listo!" }) => (
  <>
    <SVG
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <Circle cx="65.1" cy="65.1" r="62.1" />
      <Check points="100.2,40.2 51.5,88.8 29.8,67.5 " />
    </SVG>
    <Success>{successText}</Success>
  </>
);

const dash = keyframes`
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const dashCheck = keyframes`
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
`;

const Circle = styled.circle`
  fill: none;
  stroke: #73af55;
  stroke-width: 6;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  -webkit-animation: ${dash} 0.9s ease-in-out;
  animation: ${dash} 0.9s ease-in-out;
`;

const Check = styled.polyline`
  fill: none;
  stroke: #73af55;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  stroke-dashoffset: -100;
  -webkit-animation: ${dashCheck} 0.9s 0.35s ease-in-out forwards;
  animation: ${dashCheck} 0.9s 0.35s ease-in-out forwards;
`;

const Success = styled.p`
  text-align: center;
  margin: 10px 0 0;
  font-size: 2em;
  color: #73af55;
`;

const SVG = styled.svg`
  width: 200px;
  display: block;
  margin: 10px auto 0;
`;

export default TickMark;
