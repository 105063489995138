export const types = {
  POPUP: "page/SHOW_POPUP",
  REDIRECT: "page/REDIRECT"
};

export const INITIAL_STATE = {
  popup: false,
  redirect: null,
};

export const actions = {
  showPopup: (popup) => ({
    type: types.POPUP,
    popup,
  }),
  updateRedirect: (redirect) => ({
    type: types.REDIRECT,
    redirect,
  }),
};

export const selectors = {
  isPopup: ({ page }) => page.popup,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return { ...state, popup: action.popup};
    default:
      return state;
  }
};
