import { useState } from "react";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";
import { COLORS, ICONS } from "../../extras/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../reducers/user";
import { user as userActions } from "../../actions";
import { Row, Divider, Column, LoginInput, CenterColumn } from "../../components/StyledComponents";
import Welcome from "../Welcome";

// const JSON_DEFAULT = {
//   username: "nicolas",
//   email: "angimenez1996@gmail.com",
//   name: "Nicolas",
//   lastname: "Giménez",
//   country: "Uruguay",
//   city: "Montevideo",
//   address: "Aparicio Saravia 1234",
//   password: "nicolas123",
//   businessname: "Nicolás SA",
//   api_url: "https://woome.nicolasgimenez.com/wordpress",
//   api_user: "ck_5374580112df7ea90058211e08fb966beea64e5e",
//   api_pass: "cs_fb94047b150b96fc90d2e497f99652f2e82f746b",
// };
const JSON_DEFAULT = {
  email: "",
  name: "",
  lastname: "",
  country: "",
  city: "",
  address: "",
  password: "",
  businessname: "",
  url: "",
  api_user: "",
  secret_pass: "",
};

const Icon = styled.div`
  font-size: 15px;
  color: #1c1e45;
  text-align: left;
  margin-top: 50px;
  width: 90%;
`;

const TitlePrincipal = styled.h1`
  font-size: 2em;
  color: #1c1e45;
  font-weight: bolder;
  margin-top: 20px;
`;

const TitleDes = styled.h2`
  font-size: 0.8em;
  color: #aeb8d5;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Ok = styled.button`
  font-size: 0.8em;
  color: white;
  font-weight: 600;
  display: flex;
  background: #cbcbcb;
  width: 75%;
  margin: 20px 15px;
  padding: 15px 20px;
  justify-content: center;
  border-radius: 5px;
  border: none;
  background-color: ${({ disabled }) =>
    disabled ? COLORS.inactiveButton : COLORS.activeButton};
  cursor: pointer;
`;

const NoCount = styled.span`
  font-size: 0.9em;
  color: #5f6b8f;
  font-weight: 550;
  margin-right: 20px;
`;

const Register = styled(Link)`
  font-size: 0.9em;
  color: #0088ff;
  font-weight: bold;
`;

const Login = () => {
  const isLogged = useSelector(selectors.isLogged);
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON_DEFAULT);

  const handleChanges =
    (name) =>
    ({ target: { value } }) => {
      const changedUser = { ...user, [name]: value };
      setUser(changedUser);
    };

  const handleSubmit = () => {
    const {
      email,
      name,
      lastname,
      country,
      address,
      password,
      businessname,
      url,
      api_user,
      secret_pass,
    } = user;
    if (
      email &&
      name &&
      lastname &&
      country &&
      address &&
      password &&
      businessname &&
      url &&
      api_user &&
      secret_pass
    ) {
      dispatch(userActions.register(user));
    } else {
      alert("Debe ingresar usuario y contraseña para continuar");
    }
  };

  return isLogged ? (
    <Redirect to="/" />
  ) : (
    <Row>
      <CenterColumn>
        <Icon>
          <img src={ICONS.woome} />
        </Icon>
        <TitlePrincipal>Formulario de Registro</TitlePrincipal>
        <TitleDes>Usuario, contraseña y listo! A disfrutar de WOOME</TitleDes>
        <LoginInput
          placeholder="Nombre"
          value={user.name}
          onChange={handleChanges("name")}
        />
        <LoginInput
          placeholder="Apellido"
          value={user.lastname}
          onChange={handleChanges("lastname")}
        />
        <LoginInput
          placeholder="Email"
          value={user.email}
          onChange={handleChanges("email")}
        />
        <LoginInput
          placeholder="Contraseña woome"
          value={user.password}
          onChange={handleChanges("password")}
        />
        <LoginInput
          placeholder="Dirección"
          value={user.address}
          onChange={handleChanges("address")}
        />
        <LoginInput
          placeholder="País"
          value={user.country}
          onChange={handleChanges("country")}
        />
        <Divider />
        <LoginInput
          placeholder="Nombre del negocio"
          value={user.businessname}
          onChange={handleChanges("businessname")}
        />
        <LoginInput
          placeholder="Sitio web"
          value={user.url}
          onChange={handleChanges("url")}
        />
        <LoginInput
          placeholder="API de usuario"
          value={user.api_user}
          onChange={handleChanges("api_user")}
        />
        <LoginInput
          placeholder="Contraseña secreta"
          value={user.secret_pass}
          onChange={handleChanges("secret_pass")}
        />
        <Ok
          onClick={handleSubmit}
          disabled={
            !(
              user.email &&
              user.name &&
              user.lastname &&
              user.country &&
              user.address &&
              user.password &&
              user.businessname &&
              user.url &&
              user.api_user &&
              user.secret_pass
            )
          }
        >
          Registrarme
        </Ok>
        <div style={{ marginBottom: "20px" }}>
          <NoCount>¿Ya tienes cuenta?</NoCount>
          <Register to="/login">Ingresar</Register>
        </div>
      </CenterColumn>
      <Welcome
        title="Bienvenido!"
        description="Lorem lorem lorem lorem lorem"
      />
    </Row>
  );
};

export default Login;
