import ColumnItem from "./ColumnItem";

const someSelected = (itemA, idField) => (itemB) =>
  !itemA || !itemB
    ? false
    : typeof itemA === "string"
    ? itemA === itemB
    : itemA[idField] === itemB[idField];

const SelectableColumnList = ({
  items = [],
  selectedItems = [],
  onSelect,
  onDelete,
  labelField = "name",
  idField = "id",
}) => {
  const handleSelect = (value) => {
    if (typeof onSelect === "function") onSelect(value);
  };
  const handleDelete = (value) => {
    if (typeof onDelete === "function") onDelete(value);
  };

  return (
    <>
      {items.map((item, index) => (
        <ColumnItem
          key={`${item}_${index}`}
          value={item}
          selected={selectedItems.some(someSelected(item, idField))}
          onSelect={handleSelect}
          onDelete={handleDelete}
          labelField={labelField}
        />
      ))}
    </>
  );
};

export default SelectableColumnList;
