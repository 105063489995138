import { actions } from "../reducers/tag";
import { actions as errorActions } from "../reducers/error";
import * as tags from "../api/tag";

const tagActions = {
  get: (identify) => async (dispatch) => {
    dispatch(actions.getRequest());
    try {
      const result = await tags.getTag(identify);
      if (result.status === "ok")
        dispatch(actions.getSuccess(result.data ? result.data : null));
      else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.getFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  list:
    (props = {}, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.listRequest());
      try {
        const result = await tags.getTags(props);
        if (result.status === "ok") {
          dispatch(
            actions.listSuccess(
              result.data ? result.data : [],
              result.pagination
            )
          );
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.listFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  create:
    ({ name, ...rest }, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.createRequest());
      try {
        const result = await tags.createTag({
          name,
          ...rest,
        });
        if (result.status === "ok") {
          dispatch(actions.createSuccess(result.data ? result.data : []));
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.createFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  update:
    ({ identify, name }, handleSuccess) =>
    async (dispatch) => {
      dispatch(actions.updateRequest());
      try {
        if (!identify) throw Error("Identificador de atributo inválido");
        const result = await tags.updateTag({ identify, name });
        if (result.status === "ok") {
          dispatch(
            actions.updateSuccess(
              result.data ? result.data : [],
              "Actualización exitosa"
            )
          );
          handleSuccess && handleSuccess(result.data);
        } else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.updateFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  delete: (identify, handleSuccess) => async (dispatch) => {
    dispatch(actions.deleteRequest());
    try {
      const result = await tags.deleteTag(identify);
      if (result.status === "ok") {
        dispatch(actions.deleteSuccess(identify));
        handleSuccess && handleSuccess(result.data);
      } else throw Error(result.data);
    } catch (ex) {
      dispatch(actions.deleteFailure(ex.message));
      dispatch(errorActions.setError(ex.message));
    }
  },
  batch:
    (operations = {}, handleSuccess) =>
    async (dispatch) => {
      // dispatch(actions.batchRequest());
      // try {
      //   const result = await tags.batchTag(operations);
      //   if (result.status === "ok") {
      //     dispatch(
      //       actions.batchSuccess(result.data ? result.data : "Correcto")
      //     );
      //     handleSuccess && handleSuccess(result.data);
      //   } else throw Error(result.data);
      // } catch (ex) {
      //   dispatch(actions.batchFailure(ex.message));
      //   dispatch(errorActions.setError(ex.message));
      // }
    },
};

export default tagActions;
