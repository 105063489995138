import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tag as tagActions } from "../../actions";
import { selectors as tagSelectors } from "../../reducers/tag";
import BubbleList from "../BubbleList";
import PopupBody from "../Popup/PopupBody";
import PopupHeader from "../Popup/PopupHeader";
import {
  Button,
  Row,
  BubbleContainer,
  Text,
} from "../StyledComponents";

const JSON_DEFAULT = {
  name: "",
};

const TextTag = styled(Text)`
  width:50%;
`;

const RowText = styled(Row)`
  flex: 0.3;
  margin: 1.2rem 0;
`;

const Tag = ({ onChange, onSubmit, tagsSelected = [] }) => {
  const dispatch = useDispatch();
  const tags = useSelector(tagSelectors.getTags);
  const [selected, setSelected] = useState(tagsSelected);
  const [tag, setTag] = useState(JSON_DEFAULT);

  const handleChanges =
    (name) =>
    ({ target: { value } }) => {
      const changedTag = { ...tag, [name]: value };
      setTag(changedTag);
    };

  useEffect(() => {
    if (!tags.length) dispatch(tagActions.list({ per_page: 100 }));
  }, []);

  const handleSuccess = () => {
    setTag(JSON_DEFAULT);
  };

  const handleAddTag = () => {
    if (tag.name && !tags.some((item) => item.name === tag.name)) {
      dispatch(tagActions.create(tag, handleSuccess));
    }
  };

  const handleSelectTag = (tag) => {
    let updateSelected;
    if (tag && !selected.some((item) => item.id === tag.id)) {
      selected.push(tag);
      updateSelected = [...selected];
      setSelected(updateSelected);
    } else {
      updateSelected = selected.filter((item) => item.id !== tag.id);
      setSelected(updateSelected);
    }
    if (typeof onChange === "function") onChange(updateSelected);
  };

  const handleDeleteTag = (tag) => {
    if (tag && tags.includes(tag)) {
      // setTags(tags.filter((item) => item !== tag));
      setSelected(selected.filter((item) => item !== tag));
    }
  };

  return (
    <>
      <PopupHeader text="Tags" />
      <PopupBody>
        <div>
          <TextTag
            placeholder="escriba un tag para añadir"
            onChange={handleChanges("name")}
            value={tag.name}
          />
          <Button onClick={handleAddTag}>Añadir</Button>
        </div>
        <Row>
          <BubbleContainer>
            <BubbleList
              items={tags}
              selectedItems={selected}
              onSelect={handleSelectTag}
              // onDelete={handleDeleteTag}
            />
          </BubbleContainer>
        </Row>
        {typeof onSubmit === "function" ? (
          <Button onClick={onSubmit}>Confirmar</Button>
        ) : null}
      </PopupBody>
    </>
  );
};

export default Tag;
