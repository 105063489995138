import * as API from "./index";

const ATTRIBUTES_ENDPOINT = "/wc/v3/products/attributes";

export const getAttribute = (identify) =>
  API.getJSONData(`${ATTRIBUTES_ENDPOINT}/${identify}`);

export const getAttributes = ({ page = 1, per_page = 10, ...rest }) =>
  API.getJSONData(
    `${ATTRIBUTES_ENDPOINT}${API.getQueryFromObj({ page, per_page, ...rest })}`
  );

export const getTerms = (identify, { page = 1, per_page = 100, ...rest }) =>
  API.getJSONData(
    `${ATTRIBUTES_ENDPOINT}/${identify}/terms${API.getQueryFromObj({
      page,
      per_page,
      ...rest,
    })}`
  );

export const batchTerms = (identify, operations) =>
  API.getJSONData(`${ATTRIBUTES_ENDPOINT}/${identify}/terms/batch`, {
    method: "POST",
    body: JSON.stringify(operations),
  });

export const createAttribute = async (attribute) =>
  API.getJSONData(ATTRIBUTES_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(attribute),
  });

export const updateAttribute = ({ identify, ...params }) =>
  API.getJSONData(`${ATTRIBUTES_ENDPOINT}/${identify}`, {
    method: "PUT",
    body: JSON.stringify(params),
  });

export const deleteAttribute = (identify, force = true) =>
  API.getJSONData(`${ATTRIBUTES_ENDPOINT}/${identify}?force=${force}`, {
    method: "DELETE",
  });

export const batchAttribute = (operations) =>
  API.getJSONData(`${ATTRIBUTES_ENDPOINT}/batch`, {
    method: "POST",
    body: JSON.stringify(operations),
  });
