import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { COLORS, ICONS } from "../../extras/constants";
import { media as mediaActions } from "../../actions";
import { selectors as mediaSelectors } from "../../reducers/media";
import PagintationControl from "../PaginationControl";
import {
  Divider,
  ConfirmButton,
  Row as StyledRow,
  Column as StyledColumn,
} from "../StyledComponents";

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 600px;
  max-height: 500px;
  overflow-y: scroll;
`;

const Selectable = styled.a`
  background-image: url(${(props) => props.imgUrl.replaceAll(" ", "%20")});
  background-size: cover;
  display: flex;
  margin: 20px;
  cursor: pointer;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #95c8ff;
  background-color: rgba(149, 200, 255, 0.7);
`;

const Image = styled.img`
  position: absolute;
  width: 60px;
`;

const Column = styled(StyledColumn)`
  align-items: center;
`;

const Row = styled(StyledRow)`
  align-items: center;
`;

const someElement = (id) => (element) => id === element.id;

const Gallery = ({ onSubmit, defaultselection = [], limit = 5 }) => {
  const dispatch = useDispatch();
  const images = useSelector(mediaSelectors.getMedias);
  const pagination = useSelector(mediaSelectors.getPagination);
  const [page, setPage] = useState(1);
  const [selectedImages, setSelectedImages] = useState(defaultselection);
  const inputFileRef = useRef(null);

  useEffect(() => {
    dispatch(mediaActions.list({ page }));
  }, [page]);

  const handlePagination = (number) => {
    setPage(number);
  };

  const handleSelection = (image) => (e) => {
    const images = [...selectedImages];
    if (images.some(someElement(image.id))) {
      setSelectedImages(
        selectedImages.filter((value) => value.id !== image.id)
      );
    } else if (images.length < limit) {
      images.push(image);
      setSelectedImages(images);
    }
  };

  const handleSubmit = (e) => {
    if (typeof onSubmit === "function") {
      onSubmit(selectedImages);
    }
  };

  const handleClickUploadFile = (e) => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(
        mediaActions.create({ name: file.name, file }, (img) => {
          if (selectedImages.length < limit) {
            const images = [...selectedImages];
            images.push(img);
            setSelectedImages(images);
          }
        })
      );
    }
  };

  return images ? (
    <Row>
      <Column flexValue={1} backgroundcolor={COLORS.backgroundUploadImage}>
        <img src={ICONS.upload} />
        <ConfirmButton
          backgroundcolor={COLORS.uploadImageBackgroundButton}
          onClick={handleClickUploadFile}
        >
          Subir imágenes
        </ConfirmButton>
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputFileRef}
          accept="image/png, image/gif, image/jpeg"
          onChange={handleUploadFile}
        />
        <span>sólo formato png y jpg</span>
      </Column>
      <Column flexValue={3}>
        <ImagesContainer>
          {images.map((image) => {
            const {
              id,
              media_details: {
                sizes: {
                  thumbnail: { source_url },
                },
              },
            } = image;
            return (
              <Selectable
                key={String(id)}
                imgUrl={source_url}
                onClick={handleSelection(image)}
              >
                {selectedImages.some(someElement(id)) ? (
                  <>
                    <Overlay>
                      <Image src={ICONS.check} />
                    </Overlay>
                  </>
                ) : null}
              </Selectable>
            );
          })}
        </ImagesContainer>
        <PagintationControl
          currentpage={page}
          totalpages={pagination ? pagination.totalpages : 0}
          onChange={handlePagination}
        />
        <Divider customCSS="max-width: 600px;" />
        {onSubmit ? (
          <ConfirmButton onClick={handleSubmit}>Confirmar</ConfirmButton>
        ) : null}
      </Column>
    </Row>
  ) : null;
};

export default Gallery;
