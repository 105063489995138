export const types = {
  REPORT_GET_SALES_REQUEST: "reports/REPORT_GET_SALES_REQUEST",
  REPORT_GET_SALES_SUCCESS: "reports/REPORT_GET_SALES_SUCCESS",
  REPORT_GET_SALES_FAILURE: "reports/REPORT_GET_SALES_FAILURE",
  REPORT_GET_PRODUCT_TOTAL_REQUEST: "reports/REPORT_GET_PRODUCT_TOTAL_REQUEST",
  REPORT_GET_PRODUCT_TOTAL_SUCCESS: "reports/REPORT_GET_PRODUCT_TOTAL_SUCCESS",
  REPORT_GET_PRODUCT_TOTAL_FAILURE: "reports/REPORT_GET_PRODUCT_TOTAL_FAILURE",
  REPORT_GET_TOP_SELLERS_REQUEST: "reports/REPORT_GET_TOP_SELLERS_REQUEST",
  REPORT_GET_TOP_SELLERS_SUCCESS: "reports/REPORT_GET_TOP_SELLERS_SUCCESS",
  REPORT_GET_TOP_SELLERS_FAILURE: "reports/REPORT_GET_TOP_SELLERS_FAILURE",
  REPORT_GET_CUSTOMER_TOTAL_REQUEST: "reports/REPORT_GET_CUSTOMER_TOTAL_REQUEST",
  REPORT_GET_CUSTOMER_TOTAL_SUCCESS: "reports/REPORT_GET_CUSTOMER_TOTAL_SUCCESS",
  REPORT_GET_CUSTOMER_TOTAL_FAILURE: "reports/REPORT_GET_CUSTOMER_TOTAL_FAILURE",
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  error: null,
  sales: {},
  product_total: [],
  top_sellers: [],
  customer_total: [],
};

export const actions = {
  getSalesRequest: () => ({
    type: types.REPORT_GET_SALES_REQUEST,
  }),
  getSalesSuccess: (sales) => ({
    type: types.REPORT_GET_SALES_SUCCESS,
    sales,
  }),
  getSalesFailure: (error) => ({
    type: types.REPORT_GET_SALES_FAILURE,
    error,
  }),
  getProductTotalRequest: () => ({
    type: types.REPORT_GET_PRODUCT_TOTAL_REQUEST,
  }),
  getProductTotalSuccess: (product_total) => ({
    type: types.REPORT_GET_PRODUCT_TOTAL_SUCCESS,
    product_total,
  }),
  getProductTotalFailure: (error) => ({
    type: types.REPORT_GET_PRODUCT_TOTAL_FAILURE,
    error,
  }),
  getTopSellersRequest: () => ({
    type: types.REPORT_GET_TOP_SELLERS_REQUEST,
  }),
  getTopSellersSuccess: (top_sellers) => ({
    type: types.REPORT_GET_TOP_SELLERS_SUCCESS,
    top_sellers,
  }),
  getTopSellersFailure: (error) => ({
    type: types.REPORT_GET_TOP_SELLERS_FAILURE,
    error,
  }),
  getCustomerTotalRequest: () => ({
    type: types.REPORT_GET_CUSTOMER_TOTAL_REQUEST,
  }),
  getCustomerTotalSuccess: (customer_total) => ({
    type: types.REPORT_GET_CUSTOMER_TOTAL_SUCCESS,
    customer_total,
  }),
  getCustomerTotalFailure: (error) => ({
    type: types.REPORT_GET_CUSTOMER_TOTAL_FAILURE,
    error,
  }),
};

export const selectors = {
  isFetching: ({ report }) => report.isFetching,
  getMessage: ({ report }) => report.message,
  getError: ({ report }) => report.error,
  getSales: ({ report }) => report.sales,
  getProductTotal: ({ report }) => {
    let total = 0;
    report.product_total.forEach((item) => {
      total += item.total;
    });
    return total;
  },
  getCustomerTotal: ({ report }) => {
    let total = 0;
    report.customer_total.forEach((item) => {
      total += item.total;
    });
    return total;
  },
  getTopSellers: ({ report }) => report.top_sellers,
};

const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REPORT_GET_SALES_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        sales: {},
      };
    case types.REPORT_GET_SALES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        sales: action.sales,
      };
    case types.REPORT_GET_SALES_FAILURE:
      return {
        ...state,
        sales: {},
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.REPORT_GET_PRODUCT_TOTAL_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        product_total: [],
      };
    case types.REPORT_GET_PRODUCT_TOTAL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        product_total: action.product_total,
      };
    case types.REPORT_GET_PRODUCT_TOTAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
        product_total: [],
      };
    case types.REPORT_GET_TOP_SELLERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        top_sellers: [],
      };
    case types.REPORT_GET_TOP_SELLERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        top_sellers: action.top_sellers,
      };
    case types.REPORT_GET_TOP_SELLERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
        top_sellers: [],
      };
    case types.REPORT_GET_CUSTOMER_TOTAL_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        customer_total: [],
      };
    case types.REPORT_GET_CUSTOMER_TOTAL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        customer_total: action.customer_total,
      };
    case types.REPORT_GET_CUSTOMER_TOTAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
        customer_total: [],
      };
    default:
      return state;
  }
};

export default reportReducer;
