import { actions } from "../reducers/user";
import { actions as errorActions } from "../reducers/error";
import * as users from "../api/user";

const userActions = {
  login:
    ({ email, password }) =>
    async (dispatch) => {
      dispatch(actions.loginRequest());
      try {
        const result = await users.login({ email, password });
        if (result.status === "ok")
          dispatch(actions.loginSuccess(result.data ? result.data : null));
        else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.loginFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  register:
    (user) =>
    async (dispatch) => {
      dispatch(actions.registerRequest());
      try {
        const result = await users.register(user);
        if (result.status === "ok")
          dispatch(actions.registerSuccess(result.data ? result.data : null));
        else throw Error(result.data);
      } catch (ex) {
        dispatch(actions.registerFailure(ex.message));
        dispatch(errorActions.setError(ex.message));
      }
    },
  logout: () => async (dispatch) => {
    dispatch(actions.logout());
  },
};

export default userActions;
