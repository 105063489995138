import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../extras/constants";
import { Button } from "./StyledComponents";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const TextInput = styled.input`
  height: 33px;
  width: 40px;
  font-size: 16px;
  text-align: center;
  margin: 5px 10px;
`;
const TotalPages = styled.span`
  font-size: 16px;
  margin: 5px 10px;
`;

const PagintationControl = ({ currentpage, totalpages, onChange }) => {
  const [text, setText] = useState(currentpage);

  const handleComponentChanges = (number) => {
    if (
      typeof onChange === "function" &&
      (totalpages > 1 || currentpage > totalpages)
    ) {
      if (number > totalpages) {
        setText(totalpages);
        onChange(totalpages);
      } else if (number < 1) {
        setText(1);
        onChange(1);
      } else {
        setText(number);
        onChange(number);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (text && e.key === "Enter") {
      handleComponentChanges(parseInt(text));
      e.target.blur();
    }
  };

  const handleText = (e) => {
    e.preventDefault();
    if (e.target.validity.valid) setText(e.target.value);
  };

  const handleClick = (number) => (e) => {
    handleComponentChanges(parseInt(text) + number);
  };

  return (
    <Container>
      <Button onClick={handleClick(-1)}>&lt; Anterior</Button>
      <TextInput
        pattern="[0-9]*"
        value={text}
        onChange={handleText}
        onKeyDown={handleKeyDown}
      />{" "}
      de <TotalPages>{totalpages}</TotalPages>
      <Button onClick={handleClick(+1)}>Siguiente &gt;</Button>
    </Container>
  );
};

export default PagintationControl;
