import * as API from "./index";
const CONSUMER_KEY = "ck_5374580112df7ea90058211e08fb966beea64e5e";
const CONSUMER_SECRET = "cs_fb94047b150b96fc90d2e497f99652f2e82f746b";
const MEDIAS_ENDPOINT = "/wp/v2/media";

export const getMedias = ({ page = 1, per_page = 10, media_type = "image" }) =>
  API.getJSONData(
    `${MEDIAS_ENDPOINT}?media_type=${media_type}&page=${page}&per_page=${per_page}`
  );

export const createMedia = async ({ name, file }) => {
  const headers = new Headers({
    Authorization: `Basic ${window.btoa(
      unescape(encodeURIComponent(`${CONSUMER_KEY}:${CONSUMER_SECRET}`))
    )}`,
  });
  const formData = new FormData();
  formData.append("file", file);
  return API.getJSONData(
    "/wc/v3/upload-media",
    {
      method: "POST",
      body: formData,
    },
    headers
  );
};

export const modifyMedia = (params) => API.getJSONData(MEDIAS_ENDPOINT, params);

export const deleteMedia = (params) => API.getJSONData(MEDIAS_ENDPOINT, params);
