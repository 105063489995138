export const types = {
  MEDIA_LIST_REQUEST: "medias/MEDIA_LIST_REQUEST",
  MEDIA_LIST_SUCCESS: "medias/MEDIA_LIST_SUCCESS",
  MEDIA_LIST_FAILURE: "medias/MEDIA_LIST_FAILURE",
  MEDIA_CREATE_REQUEST: "medias/MEDIA_CREATE_REQUEST",
  MEDIA_CREATE_SUCCESS: "medias/MEDIA_CREATE_SUCCESS",
  MEDIA_CREATE_FAILURE: "medias/MEDIA_CREATE_FAILURE",
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  medias: null,
  error: null,
  pagination: null,
};

export const actions = {
  listRequest: () => ({
    type: types.MEDIA_LIST_REQUEST,
  }),
  listSuccess: (medias, pagination) => ({
    type: types.MEDIA_LIST_SUCCESS,
    medias,
    pagination,
  }),
  listFailure: (error) => ({
    type: types.MEDIA_LIST_FAILURE,
    error,
  }),
  createRequest: () => ({
    type: types.MEDIA_CREATE_REQUEST,
  }),
  createSuccess: (media, message) => ({
    type: types.MEDIA_CREATE_SUCCESS,
    media,
    message,
  }),
  createFailure: (error) => ({
    type: types.MEDIA_CREATE_FAILURE,
    error,
  }),
};

export const selectors = {
  isFetching: ({ media }) => media.isFetching,
  getMessage: ({ media }) => media.message,
  getError: ({ media }) => media.error,
  getMedias: ({ media }) => media.medias,
  getPagination: ({ media }) => media.pagination,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MEDIA_LIST_REQUEST:
      return { ...state, isFetching: true, message: null, error: null };
    case types.MEDIA_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        medias: action.medias,
        pagination: action.pagination,
      };
    case types.MEDIA_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.MEDIA_CREATE_REQUEST:
      return { ...state, isFetching: true, message: null, error: null };
    case types.MEDIA_CREATE_SUCCESS:
      const mediaArray = [action.media];
      mediaArray.push(...state.medias);
      return {
        ...state,
        isFetching: false,
        message: action.message,
        medias: mediaArray,
        error: null,
      };
    case types.MEDIA_CREATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    default:
      return state;
  }
};
