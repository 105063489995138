export const types = {
  USER_LOGIN_REQUEST: "users/USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "users/USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "users/USER_LOGIN_FAILURE",
  USER_LOGOUT: "users/USER_LOGOUT",
  USER_REGISTER_REQUEST: "users/USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "users/USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "users/USER_REGISTER_FAILURE",
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  error: null,
  user: null,
  logged: false,
};

export const actions = {
  loginRequest: () => ({
    type: types.USER_LOGIN_REQUEST,
  }),
  loginSuccess: (user) => ({
    type: types.USER_LOGIN_SUCCESS,
    user,
  }),
  loginFailure: (error) => ({
    type: types.USER_LOGIN_FAILURE,
    error,
  }),
  logout: () => ({
    type: types.USER_LOGOUT,
  }),
  registerRequest: () => ({
    type: types.USER_REGISTER_REQUEST,
  }),
  registerSuccess: (user) => ({
    type: types.USER_REGISTER_SUCCESS,
    user,
  }),
  registerFailure: (error) => ({
    type: types.USER_REGISTER_FAILURE,
    error,
  }),
};

export const selectors = {
  isFetching: ({ user }) => user.isFetching,
  getMessage: ({ user }) => user.message,
  getError: ({ user }) => user.error,
  getUser: ({ user }) => user.user,
  isLogged: ({ user }) => user.logged,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.USER_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        logged: false,
      };
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        user: action.user,
        logged: true,
      };
    case types.USER_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
        logged: false,
      };
    case types.USER_LOGOUT:
      return INITIAL_STATE;
    case types.USER_REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        logged: false,
      };
    case types.USER_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        user: action.user,
        logged: true,
      };
    case types.USER_REGISTER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
        logged: false,
      };
    default:
      return state;
  }
};

export default reducer;
