import { store } from "../store";

const WOOME_ENTRYPOINT = "https://woome.nicolasgimenez.com/api";

const HEADERS = new Headers({
  "Content-Type": "application/json",
});

const HEADERS_WOOME = new Headers({
  "Content-Type": "application/json",
});

export const getQueryFromObj = (params) => {
  let query = "";
  if (params && typeof params === "object") {
    query = "?";
    const arrKeys = Object.keys(params);
    arrKeys.forEach((item, index) => {
      query += `${item}=${params[item]}`;
      if (index < arrKeys.length - 1) query += "&";
    });
  }
  return query;
};

export const getJSONData = async (endpoint, params = {}, headers = HEADERS) => {
  const { user } = store.getState();
  const result = {};
  try {
    if (!user.logged) {
      throw Error("Usuario no autenticado");
    }
    const { api_user, secret_pass, url } = user.user;
    headers.set(
      "Authorization",
      `Basic ${window.btoa(
        unescape(encodeURIComponent(`${api_user}:${secret_pass}`))
      )}`
    );
    const response = await fetch(
      `${url}${url.endsWith("/") ? "" : "/"}wp-json${endpoint}`,
      {
        headers,
        ...params,
      }
    );
    if (response.ok) {
      result.pagination = {
        totalpages: response.headers.get("X-WP-TotalPages"),
        totalitems: response.headers.get("X-WP-Total"),
      };
      result.data = await response.json();
      result.status = "ok";
    } else {
      throw (await response.json());
    }
  } catch (error) {
    if(error.code){
      result.status = error.code;
      result.data = error.message;
    }else{
      result.status = "error";
      result.data = "No se pudo conectar con el servidor";
    }
  }
  return result;
};

export const getJSONWoome = async (
  endpoint,
  params = {},
  headers = HEADERS_WOOME
) => {
  const { user } = store.getState();
  let result = {};
  try {
    if (user.user) {
      const { token } = user.user;
      headers.set("X-Access-Token", `${token}`);
    } else {
      headers.set("X-Access-Token", "");
    }
    const response = await fetch(`${WOOME_ENTRYPOINT}${endpoint}`, {
      headers,
      ...params,
    });
    if (response.ok) {
      result = await response.json();
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    result.status = "error";
    result.data = error;
  }
  return result;
};
