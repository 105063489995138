export const types = {
  CATEGORY_GET_REQUEST: "categories/CATEGORY_GET_REQUEST",
  CATEGORY_GET_SUCCESS: "categories/CATEGORY_GET_SUCCESS",
  CATEGORY_GET_FAILURE: "categories/CATEGORY_GET_FAILURE",
  CATEGORY_LIST_REQUEST: "categories/CATEGORY_LIST_REQUEST",
  CATEGORY_LIST_SUCCESS: "categories/CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILURE: "categories/CATEGORY_LIST_FAILURE",
  CATEGORY_CREATE_REQUEST: "categories/CATEGORY_CREATE_REQUEST",
  CATEGORY_CREATE_SUCCESS: "categories/CATEGORY_CREATE_SUCCESS",
  CATEGORY_CREATE_FAILURE: "categories/CATEGORY_CREATE_FAILURE",
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  error: null,
  pagination: null,
  category: null,
  categories: [],
};

export const actions = {
  getRequest: () => ({
    type: types.CATEGORY_GET_REQUEST,
  }),
  getSuccess: (category) => ({
    type: types.CATEGORY_GET_SUCCESS,
    category,
  }),
  getFailure: (error) => ({
    type: types.CATEGORY_GET_FAILURE,
    error,
  }),
  listRequest: () => ({
    type: types.CATEGORY_LIST_REQUEST,
  }),
  listSuccess: (categories) => ({
    type: types.CATEGORY_LIST_SUCCESS,
    categories,
  }),
  listFailure: (error) => ({
    type: types.CATEGORY_LIST_FAILURE,
    error,
  }),
  createRequest: () => ({
    type: types.CATEGORY_CREATE_REQUEST,
  }),
  createSuccess: (category) => ({
    type: types.CATEGORY_CREATE_SUCCESS,
    category,
  }),
  createFailure: (error) => ({
    type: types.CATEGORY_CREATE_FAILURE,
    error,
  }),
};

export const selectors = {
  isFetching: ({ category }) => category.isFetching,
  getMessage: ({ category }) => category.message,
  getError: ({ category }) => category.error,
  getCategories: ({ category }) => category.categories,
  getCategory: ({ category }) => category.category,
  getPagination: ({ category }) => category.pagination,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CATEGORY_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        category: null,
      };
    case types.CATEGORY_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        category: action.category,
        pagination: action.pagination,
      };
    case types.CATEGORY_GET_FAILURE:
      return {
        ...state,
        category: null,
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.CATEGORY_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
        categories: [],
      };
    case types.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        categories: action.categories,
        pagination: action.pagination,
      };
    case types.CATEGORY_LIST_FAILURE:
      return {
        ...state,
        categories: [],
        isFetching: false,
        message: null,
        error: action.error,
      };
    case types.CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null,
      };
    case types.CATEGORY_CREATE_SUCCESS:
      const categories = [...state.categories];
      categories.push(action.category);
      return {
        ...state,
        isFetching: false,
        message: null,
        error: null,
        categories,
      };
    case types.CATEGORY_CREATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
