import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../extras/constants";

const Container = styled.div`
  min-height: 30px;
  min-width: 100px;
  border-bottom: solid
    ${({ focused }) => (focused ? COLORS.activeEdge : COLORS.inactiveEdge)} 1px;
  background-color: white;
  display: flex;
  justify-content: center;
`;
const Label = styled.label`
  align-self: stretch;
  color: ${({ focused }) => (focused ? COLORS.activeEdge : COLORS.inactiveEdge)};
`;
const Text = styled.input`
  flex: 1;
  border: none;
  outline: none;
  text-align: right;
  align-self: stretch;
  padding-right: 20px;
`;

const ImportantText = ({ title, onChange = (e) => {}, ...props }) => {
  const [focused, setFocused] = useState(false);
  return (
    <Container focused={focused}>
      <Label focused={focused}>{title}</Label>
      <Text
        type="text"
        {...props}
        onChange={onChange}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
    </Container>
  );
};

export default ImportantText;
